import { useLinkTo } from '@react-navigation/native';
import {
  AuthUser,
  ConfirmSignUpOutput,
  FetchUserAttributesOutput,
  confirmResetPassword as _confirmResetPassword,
  resetPassword as _resetPassword,
  signUp as _signUp,
  confirmSignUp,
  fetchUserAttributes,
  getCurrentUser,
  resendSignUpCode,
  signIn,
  signInWithRedirect,
  signOut,
  updateUserAttributes,
} from 'aws-amplify/auth';
import { Hub, HubCapsule } from 'aws-amplify/utils';
// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthHubEventData } from '@aws-amplify/core/dist/esm/Hub/types/AuthTypes';

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Platform } from 'react-native';
import { CombinedError } from 'urql';
import { useLoadingContext } from './LoadingProvider';
import { UrqlContext } from './UrqlProvider';
import Alert from '/Alert';
import { KEY_GREEN } from '/constants';
import {
  MeQuery,
  SurveyResponseInput,
  SystemSurveyPlacement,
  useCreateSurveySubmissionMutation,
  useDeleteMyProfileMutation,
  useMeQuery,
} from '/generated/graphql';
import { isValidEmail } from '/util';
import env from '/env';

export type AuthContextType = {
  isAuthenticating: boolean;
  fetching: boolean;
  user: AuthUser | undefined;
  userData: MeQuery['me'] | undefined;
  isAdmin: boolean;
  userAttributes: { [key: string]: any } | undefined;
  fetchUserError: CombinedError | undefined;
  hasInitialized: boolean;
  logIn: (email: string, password: string) => Promise<void>;
  federatedLogIn: (provider: CognitoIdentityProvider) => Promise<void>;
  setReturnTo: (returnTo: string) => void;
  signUp: (email: string, password: string) => Promise<void>;
  refresh: () => Promise<void>;
  confirmSignup: (email: string, code: string) => Promise<ConfirmSignUpOutput>;
  resendSignup: (email: string) => Promise<void>;
  resetPassword: (email: string) => Promise<void>;
  confirmResetPassword: (
    email: string,
    code: string,
    newPassword: string,
  ) => Promise<void>;
  setUserAttribute: (attribute: string, value: string) => Promise<void>;
  /** Clears user from state, but doesn't log them out */
  softReset: () => Promise<void>;
  /** Clears user from state and resets the urql client */
  reset: () => Promise<void>;
  deleteProfile: (surveyResponse?: SurveyResponseInput) => void;
  logOut: (returnTo?: string) => void;
};

type AuthContextProps = {};

export const AuthContext = createContext<AuthContextType>({} as any);

export const useAuthContext = () => useContext(AuthContext);

export enum CognitoIdentityProvider {
  Cognito = 'COGNITO',
  Google = 'Google',
  Facebook = 'Facebook',
  Amazon = 'LoginWithAmazon',
  Apple = 'Apple',
}

export const LoginError = {
  USER_NOT_CONFIRMED: class UserNotConfirmedError extends Error {
    name = 'UserNotConfirmedError';

    message = 'User is not confirmed';
  },
  ATTEMPT_LIMIT_EXCEEDED: class AttemptLimitExceededError extends Error {
    name = 'AttemptLimitExceededError';

    message = 'Attempt limit exceeded';
  },
  INCORRECT_LOGIN: class IncorrectLoginError extends Error {
    name = 'IncorrectLoginError';

    message = 'Incorrect username and password combination';
  },
  USER_NOT_FOUND: class UserNotFoundError extends Error {
    name = 'UserNotFoundError';

    message = 'User not found';
  },
  USER_ALREADY_EXISTS: class UserAlreadyExistsError extends Error {
    name = 'UserAlreadyExistsError';

    message = 'User already exists';
  },
  NO_PASSWORD: class NoPasswordError extends Error {
    name = 'NoPasswordError';

    message =
      'This account is not set up for password login. Please log in using your Google or Apple account.';
  },
};

export default function AuthProvider(
  props: React.PropsWithChildren<AuthContextProps>,
) {
  const linkTo = useLinkTo();
  const [returnTo, setReturnTo] = useState<string>();

  const [cognitoUser, setCognitoUser] = useState<AuthUser>();
  const [userAttributes, setUserAttributes] = useState<{ [key: string]: any }>(
    {},
  );
  const [hasInitializedCognitoUser, setHasInitializedCognitoUser] =
    useState(false);
  const [hasInitializedProfile, setHasInitializedProfile] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);

  const { resetClient } = useContext(UrqlContext);

  const [{ data: meQueryData, fetching, error: meQueryError }, me] = useMeQuery(
    {
      pause: !cognitoUser?.userId,
    },
  );
  const userData = cognitoUser?.userId ? meQueryData?.me : undefined;
  useEffect(() => {
    if (hasInitializedCognitoUser && !fetching) {
      setHasInitializedProfile(true);
    }
  }, [fetching, hasInitializedCognitoUser, meQueryError, userAttributes.sub]);

  useEffect(
    function executeReturnTo() {
      if (
        returnTo &&
        hasInitializedCognitoUser &&
        hasInitializedProfile &&
        !!userData
      ) {
        linkTo(returnTo);
        setReturnTo(undefined);
      }
    },
    [
      returnTo,
      linkTo,
      hasInitializedCognitoUser,
      hasInitializedProfile,
      userData,
    ],
  );

  const { setShowLoading, setLoadingInfo }: any = useLoadingContext();

  const [, _deleteProfile] = useDeleteMyProfileMutation();
  const [, submitSurvey] = useCreateSurveySubmissionMutation();

  const fetchCognitoUser = useCallback(async () => {
    let user: AuthUser | undefined;

    let userInitialized = false; // have we fetched the current user, if they're signed in?

    try {
      user = await getCurrentUser();
      setCognitoUser(user);
      userInitialized = true;
    } catch (error: any) {
      console.error(error);

      if (error?.name === 'UserUnAuthenticatedException') {
        // This error is thrown when the user is not signed in
        // So getting this error means successful initialization
        userInitialized = true;
      }
    } finally {
      if (userInitialized) {
        setHasInitializedCognitoUser(true);
        if (!user) setHasInitializedProfile(true); // if no user, consider profile initialized
      }
    }

    return user;
  }, []);

  const fetchCognitoUserAndAttributes = useCallback(async () => {
    let user: AuthUser | undefined;
    let attributes: FetchUserAttributesOutput | undefined;

    try {
      // Get user after calling `updateUserAttributes` so we can store in state
      user = await fetchCognitoUser();
      attributes = user && (await fetchUserAttributes());
    } catch (err) {
      console.error(err);
    }

    // Store latest user data in state
    setUserAttributes(attributes || {});
    return user;
  }, [fetchCognitoUser]);

  const setUserAttribute = useCallback(
    async (attribute: string, value: string) => {
      if (!cognitoUser) {
        throw new Error('No cognito user');
      }

      await updateUserAttributes({
        userAttributes: {
          [attribute]: value,
        },
      });

      await fetchCognitoUserAndAttributes();
    },
    [cognitoUser, fetchCognitoUserAndAttributes],
  );

  const softReset = useCallback(async () => {
    setCognitoUser(undefined);
  }, []);

  const reset = useCallback(async () => {
    setCognitoUser(undefined);
    setUserAttributes({});
    resetClient();
  }, [resetClient]);

  const handleAuthEvent = useCallback(
    async ({ payload }: HubCapsule<'auth', AuthHubEventData>) => {
      // console.log('auth event:', payload);

      switch (payload.event) {
        case 'signInWithRedirect_failure': {
          // console.log('sign in failure');
          Alert.alert('There was an error signing in. Please try again later.');
          break;
        }
        case 'signInWithRedirect':
        case 'signedIn':
          setIsAuthenticating(true);
          fetchCognitoUserAndAttributes()
            .then(() => {
              resetClient();
            })
            .finally(() => {
              setIsAuthenticating(false);
              if (env.TESTER_ONLY_MODE) {
                linkTo('/tester-dashboard');
              }
            });
          break;
        case 'customOAuthState': {
          const state = payload.data;
          setReturnTo(state);
          break;
        }
        case 'signedOut':
          reset();
          break;

        default:
          break;
      }
    },
    [fetchCognitoUserAndAttributes, linkTo, reset, resetClient],
  );

  /** Intializer effect */
  useEffect(() => {
    fetchCognitoUserAndAttributes().catch(() => {});

    const removeAuthEventListener = Hub.listen('auth', handleAuthEvent);

    return () => removeAuthEventListener?.();
  }, [handleAuthEvent, fetchCognitoUserAndAttributes]);

  const logOut = useCallback(
    async (_returnTo?: string) => {
      setShowLoading(true);
      setReturnTo(_returnTo || undefined);

      try {
        // Sign out

        await signOut();
        /**
         * In some cases when a user signs out after a prolonged session without reloading,
         * the app ends up navigating back the next time they try to enter their email to login
         * until they refresh the page. This is a hacky fix to force a refresh on web. */
        setTimeout(() => {
          if (Platform.OS === 'web') location.reload();
        }, 1500);
      } catch (error) {
        console.log(error);
      } finally {
        setShowLoading(false);
      }
    },
    [setShowLoading],
  );

  useEffect(
    function init() {
      setIsAuthenticating(true);
      fetchCognitoUserAndAttributes().finally(() => {
        setIsAuthenticating(false);
      });
    },
    [fetchCognitoUserAndAttributes],
  );

  /**
   * Sign up using Cognito with a username & password.
   * @param email User email input
   * @param password User password input
   * @returns A promise
   */
  const signUp = async (email: string, password: string) => {
    if (!isValidEmail(email)) {
      Alert.alert('Please enter a valid email');
      return;
    }

    setIsAuthenticating(true);

    email = email.toLowerCase();

    try {
      await _signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
          },
        },
      });
    } catch (error: any) {
      console.log('signup error', JSON.stringify(error));

      switch (error.code) {
        case 'UsernameExistsException': {
          throw new LoginError.USER_ALREADY_EXISTS();
        }
        default: {
          // Re-throw error
          throw (
            error?.message || 'There was an issue signing up, please try again'
          );
        }
      }
    } finally {
      setIsAuthenticating(false);
    }
  };

  const federatedLogIn = async (provider: CognitoIdentityProvider) => {
    setIsAuthenticating(true);

    try {
      await signInWithRedirect({
        provider: provider as any,
        customState: returnTo,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setIsAuthenticating(false);
    }
  };

  const logIn = async (email: string, password: string) => {
    if (!isValidEmail(email)) {
      throw new Error('Invalid email');
    }

    setIsAuthenticating(true);

    email = email.toLowerCase();

    try {
      await signIn({
        username: email,
        password,
        options: {
          clientMetadata: {
            /** Pre-auth uses this email metadata to check for existing
             * user and provide helpful error messages */
            passwordLoginEmail: email,
          },
        },
      });

      setHasInitializedCognitoUser(false);
      setHasInitializedProfile(false);

      resetClient();
      await fetchCognitoUserAndAttributes();
    } catch (error: any) {
      // If we fail to login, we should clear cognitoUser in the case it was set
      softReset();

      console.log('Failed to login', JSON.stringify(error), error);

      const incorrectUsernameOrPassword =
        /Incorrect username or password/i.test(error.message);

      const userNotConfirmed = /User is not confirmed/i.test(error.message);

      const userNotFound = /User does not exist/i.test(error.message);

      const attemptLimitExceeded = /Password attempts exceeded/i.test(
        error.message,
      );

      const noPassword = /Please sign in using your OAuth provider/i.test(
        error.message,
      );

      if (incorrectUsernameOrPassword) {
        throw new LoginError.INCORRECT_LOGIN();
      } else if (userNotConfirmed) {
        resendSignup(email);
        throw new LoginError.USER_NOT_CONFIRMED();
      } else if (userNotFound) {
        throw new LoginError.USER_NOT_FOUND();
      } else if (attemptLimitExceeded) {
        throw new LoginError.ATTEMPT_LIMIT_EXCEEDED();
      } else if (noPassword) {
        throw new LoginError.NO_PASSWORD();
      } else {
        signOut();

        throw error;
      }
    } finally {
      setIsAuthenticating(false);
    }
  };

  const confirmSignup = async (email: string, confirmationCode: string) => {
    return confirmSignUp({
      /** Transform email to lower case because there seems to be a bug on Amazon's side
       * where if a capital letter is used in the email, Cognito comes back with an error
       * "Username/client id combination not found." */
      username: email.toLowerCase(),
      confirmationCode: confirmationCode.trim(),
    });
  };

  const resendSignup = async (email: string) => {
    try {
      await resendSignUpCode({ username: email.toLowerCase() });
    } catch (error: any) {
      console.log('Error requesting new confirmation code: ', error);

      // Throw error
      throw new LoginError.ATTEMPT_LIMIT_EXCEEDED();
    }
  };

  function deleteProfile(surveyResponse?: SurveyResponseInput) {
    const _delete = async () => {
      try {
        setShowLoading(true);
        setLoadingInfo('Deleting Account...');

        const { error } = await _deleteProfile({});

        if (error) {
          Alert.alert('Failed to delete account', 'Please try again later.');
        } else {
          Alert.notify({
            message: 'Account deleted successfully!',
            color: KEY_GREEN,
          });

          /** Submit survey before logging out because logging out will
           * refresh the page on web. */
          if (surveyResponse?.questionId && surveyResponse.response.trim())
            await submitSurvey({
              input: {
                placement: SystemSurveyPlacement.DeleteAccount,
                responses: [surveyResponse],
                anonymous: true, // ensure user ID is not used
              },
            });

          logOut();
        }
      } catch (error) {
        // do something
      } finally {
        setShowLoading(false);
      }
    };

    Alert.alert(
      'Delete Account',
      'Are you sure you want to delete your account?',
      [
        {
          text: 'Delete Account',
          style: 'destructive',
          onPress: () => {
            // ** Double alert **
            Alert.alert(
              'This Cannot be Undone',
              'Your account will be deleted immediately and cannot be restored.',
              [
                {
                  text: 'Delete Account',
                  style: 'destructive',
                  onPress: _delete,
                },
                {
                  text: 'Nevermind',
                  style: 'cancel',
                },
              ],
            );
          },
        },
        {
          text: 'Cancel',
          style: 'cancel',
        },
      ],
    );
  }

  const resetPassword = async (email: string) => {
    if (!email) {
      return;
    }

    await _resetPassword({ username: email });
  };

  const confirmResetPassword = async (
    email: string,
    confirmationCode: string,
    newPassword: string,
  ) => {
    try {
      await _confirmResetPassword({
        username: email,
        confirmationCode,
        newPassword,
      });

      Alert.alert('Success', 'Your password has been changed.');
    } catch (err: any) {
      if (!err.message) {
        Alert.alert('Error', 'Something went wrong. Please try again.');
      } else if (err.message.includes('code')) {
        Alert.alert('Error', 'Invalid verification code. Please try again.');
      } else if (err.message.includes('password')) {
        //REPLACE ALERT with password tooltip displaying reqquirements
        Alert.alert('Error', 'Password not accepted.');
      }

      // Re-throw error
      throw err;
    }
  };

  const refresh = useCallback(async () => {
    await me({ requestPolicy: 'network-only' });
    await fetchCognitoUserAndAttributes();
  }, [me, fetchCognitoUserAndAttributes]);

  return (
    <AuthContext.Provider
      value={{
        signUp,
        fetching: fetching,
        confirmSignup,
        resendSignup,
        logIn,
        federatedLogIn,
        logOut,
        resetPassword,
        deleteProfile,
        confirmResetPassword,
        setUserAttribute,
        setReturnTo,
        softReset,
        reset,
        isAuthenticating,
        hasInitialized: hasInitializedCognitoUser && hasInitializedProfile,
        user: cognitoUser,
        isAdmin: userData?.admin || false,
        userData: userData || undefined,
        userAttributes: userAttributes,
        refresh,
        fetchUserError: meQueryError,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}
