/**
 * Campaign Builder hook
 *
 * For creating new campaigns - Handles:
 * - Creating and posting Campaigns and its components including CampaignPost,
 * SkilledImpactRequest(s), DonationRequest, etc...
 * - Detecting changes in state and auto-saving drafts to the cloud as the user
 * types
 * - Fetching existing drafts and exposing them for rendering
 */
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Platform } from 'react-native';
import { useClient } from 'urql';
import { DeepPartial } from '../types';
import { isEmpty, isUnderPrivileged, removeTypename } from '../util';
import { useAuthContext, useTeamContext } from '/context';
import {
  CampaignUrgency,
  CreateCampaignDocument,
  CreateCampaignMutation,
  CreateCampaignMutationVariables,
  CreateDonationRequestMutation,
  CreateDonationRequestMutationInput,
  CreateVolunteerRequestMutationInput,
  CurrencyAmount,
  FulfillCampaignConnectInviteDocument,
  FulfillCampaignConnectInviteMutation,
  FulfillCampaignConnectInviteMutationVariables,
  GetDraftsQuery,
  ResearchTopic,
  Species,
  SpeciesIucnThreatStatus,
  SpeciesSelectionInput,
  SpeciesSelectionResponse,
  TeamMemberRole,
  TranslatableText,
  UpdateCampaignDraftDocument,
  UpdateCampaignDraftMutation,
  UpdateCampaignDraftMutationInput,
  UpdateCampaignDraftMutationVariables,
  UpdateCampaignMutationInput,
  UpdateCampaignPostMutationInput,
  UpdateDonationRequestMutation,
  UpdateDonationRequestMutationInput,
  UpdateVolunteerRequestMutationInput,
  useCreateDonationRequestMutation,
  useCreateSkillRequestMutation,
  useCreateVolunteerRequestMutation,
  useDeleteDonationRequestMutation,
  useDeleteSkillRequestMutation,
  useDeleteVolunteerRequestMutation,
  useGetDraftsQuery,
  User,
  UserMention,
  UserRole,
  useUpdateDonationRequestMutation,
  useUpdateSkillRequestMutation,
  useUpdateVolunteerRequestMutation,
  VolunteerRequestDifficulty,
} from '/generated/graphql';
import { useAsyncJobContext } from '/context/AsyncJobProvider';

type CampaignBuilderHookOptions = {
  /** Default is true */
  autosaveEnabled?: boolean;
  campaignConnectInviteId?: string;
  /** Called when a new campaign draft is saved and assigned an ID */
  onAssignCampaignId: (campaignId: string) => void;
};

type CampaignBuilderHookReturns = {
  data: CampaignBuilderFormState;
  setData: (data: DeepPartial<CampaignBuilderFormState>) => void;
  /**
   * Publishes campaign
   * @param draft Publish as a draft? Default is true
   */
  publish: (
    draft: boolean,
    onSuccess?: (campaignId: string) => void,
  ) => Promise<void>;
  /** Fetches drafts and updates state */
  fetchDrafts: () => Promise<void>;
  /** Fetches an additional page of drafts */
  paginateDrafts: () => void;
  /** Loads draft with given ID into state, replacing current state */
  onSelectDraft: (campagnDraftId: string) => void;
  /** Removes draft with passed ID from `drafts` state */
  onDeleteDraft: (campaignDraftId: string) => void;
  /** Clears form state and loads in default values */
  startNewDraft: () => void;
  hasMoreDrafts: boolean;
  /** Campaign drafts fetched by user */
  drafts: GetDraftsQuery['getDrafts']['items'];
  isDraftsLoading: boolean;
  draftsError: string;
  /** Is currently saving a draft? */
  isSaving: boolean;
  hasUnsavedChanges: boolean;
};

// Default state only includes `campaign` and `post`
// because those are the required elements of the campaign
// It should not contain anything else
const DEFAULT_STATE: CampaignBuilderFormState = {
  supportNeeded: [],
  campaign: {
    name: '',
    urgency: CampaignUrgency.None,
    species: [],
  },
  post: {
    description: {
      text: '',
    },
    media: [],
    thumbnail: '',
  },
};

export default function useCampaignBuilder(
  options?: CampaignBuilderHookOptions,
): CampaignBuilderHookReturns {
  const urqlClient = useClient();

  const { activeTeam, activeTeamRole } = useTeamContext();
  const { userData } = useAuthContext();

  const { runJob } = useAsyncJobContext();

  const [, createSkillRequest] = useCreateSkillRequestMutation();
  const [, updateSkillRequest] = useUpdateSkillRequestMutation();
  const [, _deleteSkillRequest] = useDeleteSkillRequestMutation();

  const [, createVolunteerRequest] = useCreateVolunteerRequestMutation();
  const [, updateVolunteerRequest] = useUpdateVolunteerRequestMutation();
  const [, _deleteVolunteerRequest] = useDeleteVolunteerRequestMutation();

  const [, createDonationRequest] = useCreateDonationRequestMutation();
  const [, updateDonationRequest] = useUpdateDonationRequestMutation();
  const [, _deleteDonationRequest] = useDeleteDonationRequestMutation();

  const mounted = useRef(false);

  const saveDraftTimeoutRef = useRef<NodeJS.Timeout>();

  const [draftsNextToken, setDraftsNextToken] = useState<string>();
  const [
    {
      data: draftsData,
      fetching: _fetching,
      error: fetchDraftsError,
      stale: _stale,
    },
    fetchDrafts,
  ] = useGetDraftsQuery({
    variables: {
      userId: (activeTeam?.user.id || userData?.id) as string,
      nextToken: draftsNextToken,
    },
    requestPolicy: 'network-only',
    pause:
      !userData?.id ||
      (userData?.role !== UserRole.Conservationist &&
        isUnderPrivileged(TeamMemberRole.Creator, activeTeamRole)),
  });
  const fetchingDrafts = _fetching || _stale;
  const drafts = useMemo(
    () =>
      _fetching && !draftsNextToken ? [] : draftsData?.getDrafts.items ?? [],
    [_fetching, draftsData?.getDrafts.items, draftsNextToken],
  );

  const [isSaving, setIsSaving] = useState(false);

  /** Intialize state with empty values
   *  Leaving required things undefined == No default values == Not good
   */
  const [data, _imperativelySetData] = useState<CampaignBuilderFormState>({
    ...DEFAULT_STATE,
    ...(options?.campaignConnectInviteId
      ? {
          campaign_connect_invite_ids: [options.campaignConnectInviteId],
        }
      : {}),
  });
  /** Used to avoid losing user progress when updating state after saving changes */
  const dataRef = useRef(data);
  dataRef.current = data;

  const setData = (_payload: DeepPartial<CampaignBuilderFormState>) => {
    // @ts-ignore
    _imperativelySetData((prevData) => {
      const supportNeeded = _payload.supportNeeded ?? prevData?.supportNeeded;

      return {
        campaign: {
          ...prevData.campaign,
          ..._payload.campaign,
          species:
            (_payload.campaign?.species ?? prevData.campaign.species)?.sort(
              (a, b) => (a?.species?.taxonID ?? 0) - (b?.species?.taxonID ?? 0),
            ) ?? [],
          topics: (_payload.campaign?.topics ?? prevData.campaign.topics)?.sort(
            (a, b) => a?.topic?.localeCompare(b?.topic ?? '') ?? 0,
          ),
        },
        post: {
          ...prevData?.post,
          ..._payload.post,
        },
        donation_request: {
          ...prevData?.donation_request,
          ..._payload.donation_request,
        },
        volunteer_request: {
          ...prevData?.volunteer_request,
          ..._payload.volunteer_request,
        },
        // For array types, we replace instead of merge to allow for deletions.
        supportNeeded,
        campaign_connect_invite_ids:
          _payload?.campaign_connect_invite_ids ??
          prevData?.campaign_connect_invite_ids,
        skilled_impact_requests:
          _payload?.skilled_impact_requests ??
          prevData?.skilled_impact_requests,
      };
    });
  };

  /** SIDE EFFECTS */

  // Initializer
  useEffect(function init() {
    mounted.current = true;

    // Cleanup
    return () => {
      mounted.current = false;

      if (saveDraftTimeoutRef.current) {
        clearTimeout(saveDraftTimeoutRef.current);
      }
    };
  }, []);

  const startNewDraft = useCallback(function () {
    _imperativelySetData(DEFAULT_STATE);
  }, []);

  const lastUserId = useRef<string | undefined>(userData?.id);
  const lastActiveTeamId = useRef<string | undefined>(activeTeam?.id);
  useEffect(() => {
    if (
      lastUserId.current !== userData?.id ||
      lastActiveTeamId.current !== activeTeam?.id
    ) {
      // If activeTeam or current user changed, we should reset state
      startNewDraft();
    }

    lastUserId.current = userData?.id;
    lastActiveTeamId.current = activeTeam?.id;
  }, [activeTeam?.id, startNewDraft, userData?.id]);

  /** ---- */

  const generateCampaignBuilderStateFromDraft = useCallback(
    function (_draft: CampaignDraft) {
      if (!_draft) return DEFAULT_STATE;

      // Make sure the object we return is completely new so mutations
      // don't affect the source object
      const draft: Partial<CampaignDraft> = JSON.parse(JSON.stringify(_draft));

      // Extract data from draft
      const post = draft.original_post as DraftCampaignPost | undefined;
      const donation_request = draft.donation_request;
      const volunteer_request = draft.volunteer_request;
      const skilled_impact_requests = draft.skilled_impact_requests;

      // Construct into shape of CampaignBuilderFormState
      const newState: CampaignBuilderFormState = {
        campaign: {
          id: draft.id,
          name: draft.name,
          urgency: draft.urgency ?? CampaignUrgency.None,
          species: draft.species?.sort(
            (a, b) => (a?.species?.taxonID ?? 0) - (b?.species?.taxonID ?? 0),
          ),
          topics: draft.topics?.sort((a, b) => a.topic.localeCompare(b.topic)),
          big_issues: draft.big_issues?.map((issue) => ({
            id: issue.id,
            userId: issue.userId,
          })),
        },
        post: {
          ...post,
          mentions: post?.mentions?.map((m) => ({
            id: m.id,
            start: m.start,
            end: m.end,
            user: {
              id: m.user.id,
              name: m.user.name,
            },
          })),
          authors:
            post?.authors?.map((author) => ({
              id: author.id,
              name: author.name,
              profile_image: author.profile_image,
            })) ?? [],
        },
        donation_request: donation_request
          ? {
              ...donation_request,
              goals: donation_request?.goals?.sort((a, b) =>
                (a.id ?? '').localeCompare(b.id ?? ''),
              ) as DraftDonationRequestGoal[],
            }
          : undefined,
        volunteer_request,
        skilled_impact_requests,
        ...(options?.campaignConnectInviteId
          ? {
              campaign_connect_invite_ids: [options.campaignConnectInviteId],
            }
          : {}),
      };

      // Return generated CampaignBuilderFormState
      return newState;
    },
    [options?.campaignConnectInviteId],
  );

  // Handle selection of draft and loading into `data`
  const onSelectDraft = useCallback(
    function (draftId: string) {
      const draft = drafts.find((d) => d.id === draftId);

      // Guard clause - Make sure we can find a draft with ID `draftId`
      if (draft === undefined) {
        console.warn(
          'useCampaignBuilder.onSelectDraft(): ' +
            'Cannot find a campaign draft with ID "' +
            draftId +
            '"',
        );
        return;
      }

      const newState = generateCampaignBuilderStateFromDraft(
        draft as CampaignDraft,
      );

      // Initialize the `supportNeeded` field based on new state
      const supportNeeded: ('funding' | 'volunteering' | 'skills')[] = [];

      if (newState.donation_request?.id) supportNeeded.push('funding');
      if (newState.volunteer_request?.id) supportNeeded.push('volunteering');
      if (newState.skilled_impact_requests?.some((r) => !!r.id))
        supportNeeded.push('skills');

      newState.supportNeeded = supportNeeded;

      _imperativelySetData(newState);
    },
    [drafts, generateCampaignBuilderStateFromDraft],
  );

  function paginateDrafts() {
    if (draftsData?.getDrafts.nextToken) {
      setDraftsNextToken(draftsData?.getDrafts.nextToken);
    }
  }

  function onDeleteDraft(draftId: string) {
    // TODO: Replace with a cache update
    // fetchDrafts({ requestPolicy: 'network-only' });

    // If we delete a draft we are currently working on, reset state
    if (draftId === data.campaign.id) startNewDraft();
  }

  /** Helper function checking if two objects are different
   * but excluding cases where the changes are meaningless.
   * A change is considered meaningless if the previous value
   * AND new value are both one of: undefined, null, '', {} or []
   */
  function isEqual(obj1: any, obj2: any) {
    // This recursive function will go through an object
    // as deep as it goes and remove any keys with values of
    // undefined, null, '', {} or []
    function filterMeaninglessChanges(obj: any) {
      if (isEmpty(obj, true)) return;
      // If is an iterable
      if (
        typeof obj !== 'string' &&
        typeof obj[Symbol.iterator] === 'function'
      ) {
        let sanitizedArray: any[] = [];

        for (const item of obj) {
          const sanitizedItem = filterMeaninglessChanges(item);
          if (sanitizedItem !== undefined) sanitizedArray.push(sanitizedItem);
        }

        return sortArr(sanitizedArray);
        // Is an object and not an iterable
      } else if (typeof obj === 'object') {
        let sanitizedObject: any = {};

        Object.entries(obj).forEach(([key, item]) => {
          const sanitizedItem = filterMeaninglessChanges(item);
          if (sanitizedItem !== undefined) sanitizedObject[key] = sanitizedItem;
        });

        return sanitizedObject;
      } else return obj;

      // Helper function to sort arrays
      function sortArr(arr: any): any {
        if (Array.isArray(arr)) {
          return arr.sort((a, b) => {
            return JSON.stringify(a) > JSON.stringify(b) ? 1 : -1;
          });
        } else {
          return arr;
        }
      }
    }

    return _.isEqual(
      filterMeaninglessChanges(obj1),
      filterMeaninglessChanges(obj2),
    );
  }

  const formChanges = useMemo(
    function (): (keyof CampaignBuilderFormState)[] {
      if (fetchingDrafts || isSaving) return [];

      const changed: (keyof CampaignBuilderFormState)[] = [];

      type IgnoreKeys = {
        [key in keyof CampaignBuilderFormState]: string[] | true;
      };
      // Keys that will be ignored in the comparison
      // Add keys here that you don't want trigerring an auto-save
      // You can also do nested keys, even through arrays. for example: goals.__typename
      const IGNORE_KEYS: IgnoreKeys = {
        campaign: [
          'id',
          'species.species',
          'species.species.preferredVernacularName',
          'species.species.iucnThreatStatus',
          'species.species.acceptedNameUsageID',
          'topics',
        ],
        post: ['id', 'description.id', 'description.language', 'mentions.id'],
        donation_request: ['id', 'goals.id'],
        volunteer_request: ['id'],
        skilled_impact_requests: ['id', 'campaign_id', 'goals', 'expertise'],
        campaign_connect_invite_ids: true,
      };

      // Make sure we don't mutate data
      const _data = JSON.parse(JSON.stringify(data));

      // Remove `supportNeeded` because it is only used for local state
      delete _data.supportNeeded;

      // If the form is in default state, return empty
      if (isEqual(_data, DEFAULT_STATE)) return [];

      // Compare generateCampaignBuilderState(existing draft) to campaign builder state
      // using helper function. Do this for each different key of campaign builder state.
      const existingDraft = drafts.find(
        (draft) => draft.id === _data.campaign.id,
      );

      // If this campaign does not have an ID yet, that means it is new and we should post everything
      // that is not empty.
      if (!_data.campaign.id) {
        // Campaign and CampaignPost must always be posted
        changed.push('campaign', 'post');

        // Check that these exist before adding them to list of things updated
        if (!isEmpty(_data.donation_request)) changed.push('donation_request');
        if (!isEmpty(_data.volunteer_request))
          changed.push('volunteer_request');
        if (!isEmpty(_data.skilled_impact_requests))
          changed.push('skilled_impact_requests');
      } else {
        // If a draft already exists, we should check if changes are present when compared
        // to form state.
        // In the case changes are not present, we add nothing to the `changed` array.
        const previousState =
          existingDraft &&
          removeTypename(
            generateCampaignBuilderStateFromDraft(
              existingDraft as CampaignDraft,
            ),
          );

        // Remove `supportNeeded` because it is only used for local state
        delete previousState?.supportNeeded;

        // Make sure we don't mutate
        const currentState: CampaignBuilderFormState = removeTypename(_data);

        const allKeys = new Set(
          Object.keys(currentState).concat(Object.keys(previousState ?? {})),
        );

        Array.from(allKeys).forEach((key) => {
          const _key = key as keyof CampaignBuilderFormState;

          const ignoreKeysEntry = IGNORE_KEYS[_key];

          if (ignoreKeysEntry === true) {
            delete previousState?.[_key];
            delete currentState?.[_key];
          } else {
            // Remove keys we want to ignore
            ignoreKeysEntry?.forEach((ignoreKey) => {
              // Recursive function removing keys at any level
              function removeKey(keyToRemove: string, targetObj: any) {
                const [currentKey, ...nextKeyParts] = keyToRemove.split('.');

                // If is an array
                if (
                  typeof targetObj !== 'string' &&
                  // @ts-ignore
                  typeof targetObj?.[Symbol.iterator] === 'function'
                ) {
                  if (nextKeyParts.length) {
                    // If we are not yet at target level, recurse...
                    targetObj.forEach((item: any) => {
                      removeKey(keyToRemove, item);
                    });
                  } else {
                    // @ts-ignore
                    targetObj.forEach((item: any) => {
                      delete item[currentKey];
                    });
                  }
                } else {
                  if (nextKeyParts.length) {
                    removeKey(nextKeyParts.join('.'), targetObj?.[currentKey]);
                  } else {
                    delete targetObj?.[currentKey];
                  }
                }
              }

              removeKey(ignoreKey, previousState?.[_key]);
              removeKey(ignoreKey, currentState?.[_key]);
            });
          }

          /** DEBUG */
          // console.log(
          //   'Comparing',
          //   'previous',
          //   previousState?.[_key],
          //   'current',
          //   currentState[_key],
          //   'isEqual?',
          //   isEqual(previousState?.[_key], currentState[_key]),
          // );

          // If this component of the campaign contains changes, add to `changed`
          if (!isEqual(previousState?.[_key], currentState[_key])) {
            /** DEBUG */
            // console.log(
            //   _key,
            //   'changed.\nprevious:',
            //   previousState?.[_key],
            //   ',\ncurrent: ',
            //   currentState[_key],
            // );

            changed.push(_key);
          }
        });
      }

      /** DEBUG */
      // console.log('hasFormChanged()', changed);

      // Return array of changes
      return changed;
    },
    [
      data,
      drafts,
      fetchingDrafts,
      generateCampaignBuilderStateFromDraft,
      isSaving,
    ],
  );

  useEffect(() => {
    /** Warn user about losing changes if they close the window (web) */
    if (formChanges.length && Platform.OS === 'web') {
      window.onbeforeunload = function () {
        return 'You have unsaved changes. Are you sure you want to leave?';
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [formChanges]);

  /**
   * Schema object constructor functions
   */

  /** Generate schema-shaped Campaign */
  const generateCampaignObject = useCallback(function (draft = false) {
    let campaign: Partial<UpdateCampaignMutationInput> = {
      name: dataRef.current.campaign.name,
      urgency: dataRef.current.campaign.urgency,
      species: dataRef.current.campaign.species?.map((s) => ({
        speciesTaxonID: s?.species?.taxonID,
        vernacularName: s?.vernacularName,
      })) as SpeciesSelectionInput[],
      // habitats: dataRef.current.campaign.habitats?.map((h) => JSON.stringify(h)),
      topics: dataRef.current.campaign.topics?.map((topic) => ({
        topic: topic.topic,
      })),
      big_issues: dataRef.current.campaign.big_issues?.map((issue) => ({
        id: issue.id,
      })),
      draft,
    };

    /** If we are publishing publicly (with draft == false), make sure to set createdAt to now */
    if (draft === false) campaign.created_at = new Date().toISOString(); // TODO: Make sure this works

    return campaign;
  }, []);

  /** Generate schema-shaped CampaignPost */
  const generateCampaignPost = useCallback(function (draft: boolean) {
    const campaignPost: Partial<UpdateCampaignPostMutationInput> = {
      description: {
        text: dataRef.current.post.description?.text ?? '',
      },
      authors: dataRef.current.post.authors?.map((author) => ({
        id: author.id,
      })),
      mentions: dataRef.current.post.mentions?.map((mention) => ({
        id: mention.id,
        start: mention.start,
        end: mention.end,
        userId: mention.user.id,
      })),
      media: dataRef.current.post.media ?? [],
      thumbnail: dataRef.current.post.thumbnail ?? '',
      location: dataRef.current.post.location ?? '',
      latitude: dataRef.current.post.latitude,
      longitude: dataRef.current.post.longitude,
    };

    /** If this is not a draft, make sure to include `createdAt` */
    if (!draft) {
      campaignPost.created_at = new Date().toISOString();
    }

    return campaignPost;
  }, []);

  /** Generate schema-shaped VolunteerRequest */
  const generateVolunteerRequest = useCallback(function ():
    | UpdateVolunteerRequestMutationInput
    | CreateVolunteerRequestMutationInput {
    const request = { ...dataRef.current.volunteer_request };
    // @ts-ignore
    delete request.__typename;

    request.point_of_contact = { ...request?.point_of_contact };
    // @ts-ignore
    delete request.point_of_contact.__typename;

    return request;
  },
  []);

  /** Generate schema-shaped skilled impact requests */
  const generateSkilledImpactRequests = useCallback(function (): any[] {
    const SkilledImpactRequests = dataRef.current?.skilled_impact_requests;

    return SkilledImpactRequests?.map((skillImpReq) => {
      const newRequest: any = {
        due_date: skillImpReq.due_date,
        our_contribution: skillImpReq.our_contribution,
        any_language_apply: skillImpReq.any_language_apply,
        goals: skillImpReq.goals?.map((goal) => ({
          title: goal.title,
          description: goal.description,
        })),
        expertise: skillImpReq.expertise?.map((expertise) => ({
          name: expertise.name ?? '',
          description: expertise.description ?? '',
        })),
        expertise_required: skillImpReq.expertise_required,
        preferred_languages: skillImpReq.preferred_languages,
        required_languages: skillImpReq.required_languages,
      };

      if (skillImpReq.id) {
        // If updating existing request, specify ID
        newRequest.id = skillImpReq.id;
      } else {
        // Otherwise, specify skill
        newRequest.skill = skillImpReq.skill;
      }

      return newRequest;
    }) as any[];
  }, []);

  /** Generate DonationRequest object */
  const generateDonationRequest = useCallback(
    function (): UpdateDonationRequestMutationInput {
      const donationRequest = {
        id: dataRef.current.donation_request?.id,
        goals: dataRef.current.donation_request?.goals?.map((goal) => ({
          id: goal.id,
          amount: goal.amount,
          title: goal.title,
        })),
      } as UpdateDonationRequestMutationInput;

      return donationRequest;
    },
    [],
  );

  /** API request functions */

  /** Posts `VolunteerRequest` and sets ID for new entries in state if successful */
  const postVolunteerRequest = useCallback(
    async function (): Promise<any> {
      const volunteerRequest = generateVolunteerRequest();

      let result;

      if (volunteerRequest.id) {
        result = await updateVolunteerRequest({
          input: volunteerRequest as UpdateVolunteerRequestMutationInput,
        });
      } else {
        result = await createVolunteerRequest({
          input: volunteerRequest as CreateVolunteerRequestMutationInput,
          campaignId: dataRef.current.campaign.id as string,
        });

        /** Set ID in state */
        setData({
          volunteer_request: {
            ...dataRef.current.volunteer_request,
            id: result.data?.createVolunteerRequest.id,
          } as DraftVolunteerRequest,
        });
      }

      return result;
    },
    [generateVolunteerRequest, createVolunteerRequest, updateVolunteerRequest],
  );

  /** Posts `SkilledImpactRequests` and sets IDs for new entries in state if successful */
  const postSkilledImpactRequests = useCallback(
    async function (): Promise<undefined> {
      const skillRequests = generateSkilledImpactRequests();

      if (!skillRequests?.length) return;

      // Get existing draft and convert to campaign builder state format for comparison
      const existingDraft = drafts.find(
        (d) => d.id === dataRef.current.campaign.id,
      );
      const existingRequests =
        existingDraft &&
        generateCampaignBuilderStateFromDraft(existingDraft as CampaignDraft)
          .skilled_impact_requests;

      const createUpdateRequests =
        (skillRequests.map((request) => {
          const existingRequest = existingRequests?.find(
            (req) => req.id === request.id,
          );

          // If request is identical to existing draft, do nothing
          if (_.isEqual(existingRequest, request)) return undefined;
          // If it already exists, update it
          else if (existingRequest) {
            // Exclude `skill` from data if request already exists
            const { skill, ..._request } = request;

            return updateSkillRequest({
              input: { ..._request, id: existingRequest.id },
            });
          }
          // If it does not exist, create it
          else {
            return createSkillRequest({
              campaignId: dataRef.current.campaign.id as string,
              input: request,
            });
          }
        }) as Promise<any>[]) ?? [];

      const deleteRequests =
        (existingRequests
          // Filter out existing requests that still exist in form state
          ?.filter((req) => !skillRequests.find((_req) => _req.id === req.id))
          .map((existingRequest) => {
            return _deleteSkillRequest({
              id: existingRequest.id as string,
            });
          }) as Promise<any>[]) ?? [];

      // console.log(
      //   'postRequests making',
      //   createUpdateRequests.length,
      //   ' create/update requests and ',
      //   deleteRequests.length,
      //   ' delete requests'
      // );

      const results = await Promise.all([
        ...createUpdateRequests,
        ...deleteRequests,
      ]);

      /** UPDATE STATE */

      // If we have not created/update any skill requests, return
      if (!createUpdateRequests.length) return;

      const newSkillRequests = Array.from(
        dataRef.current.skilled_impact_requests ?? [],
      );

      // For all skill requests that still exist, set IDs in form state if they don't already exist
      results.slice(0, createUpdateRequests.length).forEach((result, index) => {
        // We're only worried about setting ID in state after create requests...
        if (result.data?.createSkillRequest) {
          // Use index to assign id to correct skill request because createUpdateRequest is a mapping of
          // skillRequests, which is mapped from state so it will be in the correct order
          newSkillRequests[index].id = result.data.createSkillRequest.id;
        }
      });

      // Update state
      setData({
        skilled_impact_requests: newSkillRequests,
      });
    },
    [
      generateSkilledImpactRequests,
      drafts,
      generateCampaignBuilderStateFromDraft,
      updateSkillRequest,
      createSkillRequest,
      _deleteSkillRequest,
    ],
  );

  /** Posts `DonationRequest` and sets ID for new entries in state if successful */
  const postDonationRequest = useCallback(
    async function (): Promise<any> {
      const donationRequest = generateDonationRequest();

      let result;

      if (donationRequest.id) {
        result = await updateDonationRequest({
          input: donationRequest as UpdateDonationRequestMutationInput,
        });
      } else {
        result = await createDonationRequest({
          input: donationRequest as CreateDonationRequestMutationInput,
          campaignId: dataRef.current.campaign.id as string,
        });
      }

      let id = dataRef.current.donation_request?.id;

      // If we have a new donation request, set the ID
      if (
        (result.data as CreateDonationRequestMutation)?.createDonationRequest
          ?.id
      ) {
        id = (result.data as CreateDonationRequestMutation)
          .createDonationRequest!.id;
      }

      let goals = dataRef.current.donation_request?.goals;

      if (
        (result.data as CreateDonationRequestMutation)?.createDonationRequest
          ?.goals
      ) {
        goals = (result.data as CreateDonationRequestMutation)
          .createDonationRequest!.goals;
      }

      if (
        (result.data as UpdateDonationRequestMutation)?.updateDonationRequest
      ) {
        goals = (result.data as UpdateDonationRequestMutation)
          .updateDonationRequest!.goals;
      }

      /** Set ID and goals in state */
      setData({
        donation_request: {
          ...dataRef.current.donation_request,
          id,
          goals,
        },
      });

      return result;
    },
    [createDonationRequest, generateDonationRequest, updateDonationRequest],
  );

  const deleteVolunteerRequest = useCallback(
    async function () {
      if (!dataRef.current.volunteer_request?.id) return;

      const { error } = await _deleteVolunteerRequest({
        deleteVolunteerRequestId: dataRef.current.volunteer_request.id,
      });

      if (error) throw error;

      /** Update state */
      _imperativelySetData((prev) => ({
        ...prev,
        volunteer_request: undefined,
      }));
    },
    [_deleteVolunteerRequest],
  );

  const deleteDonationRequest = useCallback(
    async function () {
      if (!dataRef.current.donation_request?.id) return;

      const { error } = await _deleteDonationRequest({
        deleteDonationRequestId: dataRef.current.donation_request.id,
      });

      if (error) throw error;

      /** Update state */
      _imperativelySetData((prev) => ({
        ...prev,
        donation_request: undefined,
      }));
    },
    [_deleteDonationRequest],
  );

  const deleteSkillRequests = useCallback(
    async function () {
      if (!dataRef.current.skilled_impact_requests?.length) return;

      const id = dataRef.current.skilled_impact_requests[0].id;

      if (!id) return;

      const { error } = await _deleteSkillRequest({ id });

      if (error) throw error;

      /** Update state */
      _imperativelySetData((prev) => ({
        ...prev,
        skilled_impact_requests: [],
      }));
    },
    [_deleteSkillRequest],
  );

  const isPublishBusy = useRef(false);

  /**
   * Post campaign to database
   * @param draft Publish as draft? Default is true
   * @returns ID of campaign
   */
  const publish = useCallback(
    async function (draft = true, onSuccess?: (campaignId: string) => void) {
      try {
        didPublishErr.current = false;

        /** Avoid ever calling this more than once while running... */
        if (isPublishBusy.current) {
          return;
        }
        isPublishBusy.current = true;

        if (isSaving) return;

        setIsSaving(true);

        // Determine what has changed, and what needs to be published

        // No changes, do nothing (unless we are publishing with draft = false)
        if (formChanges.length === 0 && draft !== false) {
          setIsSaving(false);
          return;
        }

        // Here we will store all the promises we want to await at the end
        const createUpdateRequests: (() => Promise<any>)[] = [];
        // Separate delete requests so we can run them first.
        const deleteRequests: (() => Promise<any>)[] = [];

        // We must generate the campaign for all cases
        const campaign = generateCampaignObject(draft);

        // For each item that could have been changed, if changed, generate necessary items
        // and post them.

        const input: UpdateCampaignDraftMutationInput = {
          userId: activeTeam?.user.id || userData!.id,
        };

        /** CAMPAIGN */
        input.campaign = campaign;

        let campaignId = data?.campaign.id;

        /** CAMPAIGN POST */
        if (formChanges.includes('post') || draft === false) {
          // Generate and post CampaignPost
          const post = generateCampaignPost(draft);
          // const _postCampaign = postCampaignPost(post);
          // requests.push(_postCampaign);
          input.post = post;
        }

        if (campaignId) {
          // Update if draft already exists
          const { error } = await urqlClient
            .mutation<
              UpdateCampaignDraftMutation,
              UpdateCampaignDraftMutationVariables
            >(UpdateCampaignDraftDocument, {
              id: campaignId,
              input: {
                ...input,
                campaign: {
                  ...input.campaign,
                  draft: true,
                },
              },
            })
            .toPromise();

          if (error) throw error;
        } else {
          // .. Create new draft otherwise
          const { data: newCampaign, error } = await urqlClient
            .mutation<CreateCampaignMutation, CreateCampaignMutationVariables>(
              CreateCampaignDocument,
              {
                input: {
                  ...input,
                  campaign: {
                    ...input.campaign,
                    draft: true,
                  },
                },
              },
            )
            .toPromise();

          if (error) throw error;

          // Set the ID in state
          setData({ campaign: { id: newCampaign?.createCampaign.id } });

          if (newCampaign) {
            options?.onAssignCampaignId(newCampaign.createCampaign.id);
          }

          campaignId = newCampaign?.createCampaign.id;
        }

        /** For support requests, we want to check `supportNeeded` and delete
         * requests if they exist in drafts but no longer selected in `supportNeeded`
         *
         * NOTE: We do not update state if we delete support requests to allow users
         * to re-select skills and still maintain their data in state until
         * they navigate away from CampaignBuilder - Can be a life saver for users
         * in some edge cases.
         */

        /** DONATION REQUEST */
        if (
          data.supportNeeded?.includes('funding') &&
          formChanges.includes('donation_request')
        ) {
          createUpdateRequests.push(postDonationRequest);
        } else if (
          !data.supportNeeded?.includes('funding') &&
          data.donation_request?.id
        ) {
          deleteRequests.push(deleteDonationRequest);
        }

        /** VOLUNTEER REQUEST */
        if (
          data.supportNeeded?.includes('volunteering') &&
          formChanges.includes('volunteer_request')
        ) {
          createUpdateRequests.push(postVolunteerRequest);
        } else if (
          !data.supportNeeded?.includes('volunteering') &&
          data.volunteer_request?.id
        ) {
          deleteRequests.push(deleteVolunteerRequest);
        }

        /** SKILLED IMPACT REQUESTS */
        if (formChanges.includes('skilled_impact_requests')) {
          // Generate and post skilled impact requests
          createUpdateRequests.push(postSkilledImpactRequests);
        } else if (
          !data.supportNeeded?.includes('skills') &&
          data.skilled_impact_requests?.length
        ) {
          // Delete all skilled impact requests if skills are no longer needed
          deleteRequests.push(deleteSkillRequests);
        }

        const deleteResults = await Promise.all(
          deleteRequests.map((fn) => fn()),
        );
        const deleteErrors = deleteResults.filter((result) => !!result?.error);

        if (deleteErrors.length) throw new Error('');

        const createUpdateResults = await Promise.all(
          createUpdateRequests.map((fn) => fn()),
        );

        // If any requests fail, throw an error
        const createUpdateErrors = createUpdateResults.filter(
          (result) => !!result?.error,
        );
        if (createUpdateErrors.length) throw new Error('');

        /** If everything went well and user wishes to publish, do it now */
        if (draft === false) {
          const { error } = await urqlClient

            .mutation<
              UpdateCampaignDraftMutation,
              UpdateCampaignDraftMutationVariables
            >(UpdateCampaignDraftDocument, {
              id: campaignId!,
              input: {
                campaign: {
                  draft: false,
                },
                userId: activeTeam?.user.id || userData!.id,
              },
            })
            .toPromise();

          if (error) throw error;

          if (data.campaign_connect_invite_ids?.length) {
            runJob({
              loadingText({ totalTasks }) {
                return `Fulfilling ${totalTasks} Campaign Connect invite(s)...`;
              },
              successText({ totalTasks }) {
                return `Fulfilled ${totalTasks} Campaign Connect invite(s)!`;
              },
              failedText() {
                return `Some Campaign Connect invites could not be fulfilled.`;
              },
              tasks: data.campaign_connect_invite_ids.map((inviteId) => ({
                async job() {
                  const { error: fulfillError } = await urqlClient
                    .mutation<
                      FulfillCampaignConnectInviteMutation,
                      FulfillCampaignConnectInviteMutationVariables
                    >(FulfillCampaignConnectInviteDocument, {
                      campaignId: campaignId!,
                      inviteId,
                    })
                    .toPromise();

                  if (fulfillError) throw fulfillError;
                },
              })),
            });
          }
        } else {
          // Then, update drafts
          setDraftsNextToken(undefined);
          await fetchDrafts();
        }

        onSuccess?.(campaignId!);
      } catch (err) {
        didPublishErr.current = true;
        console.log('ERROR WHILE SAVING');
        console.log(err);

        // setHasChanged(hasFormChanged().length > 0);
        setIsSaving(false); // Set saving to false now since we are about to re-throw the error

        // Re-throw error for handling outside of useCampaignBuilder
        throw err;
      } finally {
        setIsSaving(false);
        isPublishBusy.current = false;
      }
    },
    [
      isSaving,
      formChanges,
      generateCampaignObject,
      activeTeam?.user.id,
      userData,
      data?.campaign.id,
      data.supportNeeded,
      data.donation_request?.id,
      data.volunteer_request?.id,
      data.skilled_impact_requests?.length,
      data.campaign_connect_invite_ids,
      fetchDrafts,
      generateCampaignPost,
      urqlClient,
      options,
      postDonationRequest,
      deleteDonationRequest,
      postVolunteerRequest,
      deleteVolunteerRequest,
      postSkilledImpactRequests,
      deleteSkillRequests,
      runJob,
    ],
  );

  const didPublishErr = useRef(false);

  const debouncedPublishDraft = useCallback(() => {
    if (saveDraftTimeoutRef.current) {
      clearTimeout(saveDraftTimeoutRef.current);
    }

    saveDraftTimeoutRef.current = setTimeout(() => {
      publish(true);
    }, 10000);
  }, [publish]);

  // Draft auto-save handler
  useEffect(
    function autosaveDraft() {
      // If autosave behavior is not desired, do nothing
      if (options?.autosaveEnabled === false) return;

      // If this is called while we are saving, do nothing because
      // we have a side effect that checks after every save to make
      // sure everything is actually up to date. We don't want to check
      // for changes mid-save because we will get false positives.
      if (isSaving) return;

      if (didPublishErr.current) {
        didPublishErr.current = false;
        return;
      }

      if (saveDraftTimeoutRef.current)
        clearTimeout(saveDraftTimeoutRef.current);

      if (formChanges.length) {
        debouncedPublishDraft();
      }
    },
    [
      data,
      debouncedPublishDraft,
      formChanges,
      isSaving,
      options?.autosaveEnabled,
    ],
  );

  useEffect(() => {
    // If autosave is disabled and we have a scheduled save, clear it
    if (options?.autosaveEnabled === false && saveDraftTimeoutRef.current) {
      clearTimeout(saveDraftTimeoutRef.current);
      saveDraftTimeoutRef.current = undefined;
    }
  }, [options?.autosaveEnabled]);

  /** Returns */
  return {
    data: data,
    setData,
    drafts,
    isDraftsLoading: fetchingDrafts,
    draftsError: fetchDraftsError
      ? 'There was a problem fetching your drafts.'
      : '',
    hasMoreDrafts: !!draftsData?.getDrafts.nextToken,
    fetchDrafts: async () => fetchDrafts(),
    paginateDrafts,
    onSelectDraft,
    onDeleteDraft,
    startNewDraft,
    isSaving,
    hasUnsavedChanges: !!formChanges.length,
    publish,
  };
}

export type CampaignBuilderFormState = {
  supportNeeded?: ('funding' | 'volunteering' | 'skills')[];
  /** Campaign Model */
  campaign: DraftCampaign;
  /** CampaignPost Model */
  post: DraftCampaignPost;
  /** SkilledImpactRequests Model */
  skilled_impact_requests?: DraftSkilledImpactRequest[];
  /** DonationRequest Model */
  donation_request?: DraftDonationRequest;
  /** VolunteerRequest Model */
  volunteer_request?: DraftVolunteerRequest;
  /** CampaignConnectInvites Model */
  campaign_connect_invite_ids?: string[];
};

type CampaignDraft = DraftCampaign & {
  original_post: Required<DraftCampaignPost>;

  skilled_impact_requests?: Required<DraftSkilledImpactRequest>[];

  donation_request?: Required<DraftDonationRequest>;
  volunteer_request?: Required<DraftVolunteerRequest>;
};

/**
 * Database model types (shaped after queries)
 */
type DraftCampaign = {
  id?: string;
  name?: string;
  urgency: CampaignUrgency;
  species?: DraftCampaignSpeciesSelection[]; // The input only calls for an object with a key `id`
  topics?: DraftCampaignTopic[];
  big_issues?: DraftBigIssue[];
};

interface DraftCampaignSpecies
  extends Pick<
    Species,
    | 'taxonID'
    | 'acceptedNameUsageID'
    | 'preferredVernacularName'
    | 'canonicalName'
  > {
  iucnThreatStatus: Pick<SpeciesIucnThreatStatus, 'taxonID' | 'threatStatus'>;
}

interface DraftCampaignSpeciesSelection
  extends Pick<SpeciesSelectionResponse, 'vernacularName'> {
  species: DraftCampaignSpecies;
}

interface DraftCampaignTopic extends Pick<ResearchTopic, 'topic'> {}

type DraftCampaignPostAuthor = {
  id: string;
  name: string;
  profile_image: string;
};

type DraftUserMention = Pick<UserMention, 'id' | 'start' | 'end'> & {
  user: Pick<User, 'id' | 'name'>;
};

type DraftCampaignPost = {
  id?: string;
  media?: string[];
  authors?: DraftCampaignPostAuthor[];
  mentions?: DraftUserMention[];
  thumbnail?: string;
  description?: Pick<TranslatableText, 'text'> & {
    id?: string;
  };
  location?: string;
  longitude?: string;
  latitude?: string;
};

type DraftSkilledImpactRequest = {
  id?: string;
  skill: string;
  due_date: number;
  our_contribution: string;
  any_language_apply: boolean;
  goals: DraftSkilledImpactProjectGoal[];
  expertise: DraftSkilledImpactExpertise[];
  expertise_required: boolean;
  preferred_languages: string[];
  required_languages: string[];
};

type DraftSkilledImpactExpertise = {
  description: string;
  name: string;
};

type DraftSkilledImpactProjectGoal = {
  title: string;
  description: string;
};

export type DraftDonationRequest = {
  id?: string;
  goals: DraftDonationRequestGoal[];
};

export type DraftDonationRequestGoal = {
  id?: string;
  title: string;
  amount: number;
  total_donated?: CurrencyAmount;
};

type DraftVolunteerRequest = {
  id?: string;
  accessibility_details: string;
  bathrooms_details: string;
  kid_friendly_details: string;
  accessibility_friendly: boolean;
  activity_waiver_uri: string;
  additional_directions: string;
  bathrooms_nearby: boolean;
  date: string;
  description: string;
  difficulty: VolunteerRequestDifficulty;
  difficulty_details: string;
  kid_friendly: boolean;
  know_before_you_go: string;
  location: string;
  latitude: number;
  longitude: number;
  volunteers_needed: number;
  point_of_contact: DraftVolunteerRequestPointOfContact;
};

type DraftVolunteerRequestPointOfContact = {
  userId?: string;
  name?: string;
  email?: string;
  phone_number?: string;
  profile_image?: string;
  position?: string;
};

type DraftBigIssue = {
  id: string;
  userId: string;
};
