import { Resolver } from '@urql/exchange-graphcache';
import cursorPagination from './cursorPagination';
import openSearchPagination from './openSearchPagination';
import getNotificationsForUser from './Query/getNotificationsForUser';

export default {
  Query: {
    getCampaigns: cursorPagination('PaginatedCampaigns'),
    getConnections: cursorPagination('PaginatedConnections'),
    getDrafts: cursorPagination('PaginatedCampaigns'),
    getDirectConversation: cursorPagination('PaginatedDirectMessages'),
    getNotificationsForUser: getNotificationsForUser,
    getPartnerships: cursorPagination('PaginatedPartnerships'),
    getRecentCampaigns: cursorPagination('PaginatedCampaigns'),
    searchResearchTopics: cursorPagination('PaginatedResearchTopics'),
    getOrganizationsInBounds: cursorPagination('PaginatedUsers'),
    getSpeciesUserContentMedia: cursorPagination(
      'PaginatedSpeciesUserContentMedia',
    ),
    getSpeciesUserContentTopFacts: cursorPagination(
      'PaginatedSpeciesUserContentTopFacts',
    ),
    listCampaignConnectInvitesForUser: cursorPagination(
      'PaginatedCampaignConnectInvites',
    ),
    listCreativeConnectProjects: cursorPagination(
      'PaginatedCreativeConnectProjects',
    ),
    listDirectConversations: cursorPagination('PaginatedDirectConversations'),
    listGroupDiscussionCategories: cursorPagination(
      'PaginatedGroupDiscussionCategories',
    ),
    listJobPosts: cursorPagination('PaginatedJobPosts'),
    listNewsPosts: cursorPagination('PaginatedNewsPosts'),
    listUserReports: cursorPagination('PaginatedUserReports'),
    searchUsers: (parent, args, cache, info) => {
      if (info.variables.skipMerge) {
        return cache.resolve('Query', 'searchUsers', args);
      }
      return cursorPagination('PaginatedUsers')(parent, args, cache, info);
    },
    searchGroups: cursorPagination('PaginatedGroups'),
    searchSkills: cursorPagination('PaginatedSkills'),
    searchSpecies: openSearchPagination('SpeciesSearchResponse'),
  },
  CreativeConnectOverallAnalyticsResponse: {
    projects: cursorPagination(
      'PaginatedCreativeConnectOverallAnalyticsProjects',
    ),
  },
  CreativeConnectProject: {
    collaborators: cursorPagination('PaginatedCreativeConnectCollaborators'),
  },
  DonationRequest: {
    donations: cursorPagination('PaginatedDonations'),
    my_donations: cursorPagination('PaginatedDonations'),
  },
  DiscussionBoard: {
    messages: cursorPagination('PaginatedMessages'),
  },
  Event: {
    attendees: cursorPagination('PaginatedEventAttendees'),
  },
  Group: {
    members: cursorPagination('PaginatedGroupMembers'),
  },
  SharedContentBucket: {
    documents: cursorPagination('PaginatedSharedDocuments'),
  },
  User: {
    partnerships: cursorPagination('PaginatedPartnerships'),
    donations: cursorPagination('PaginatedDonations'),
    moments: cursorPagination('PaginatedMomentInNature'),
  },
  Campaign: {
    posts: cursorPagination('PaginatedCampaignPosts'),
  },
  NewComment: {
    replies: cursorPagination('PaginatedNewComments'),
  },
  Species: {
    vernacularNames: cursorPagination('PaginatedSpeciesVernacularNames'),
  },
  PostInteractions: {
    comments: cursorPagination('PaginatedNewComments'),
  },
} as {
  [key: string]: {
    [key: string]: Resolver;
  };
};
