import {
  FontAwesome,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
} from '@expo/vector-icons';
import React from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import DeleteAccountSurvey from './DeleteAccountSurvey';
import Avatar from '/components/Avatar';
import Button from '/components/Button';
import CheckmarkSwitch from '/components/CheckmarkSwitch';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import SectionContainer from '/components/common/Section/SectionContainer';
import SectionText from '/components/common/Section/SectionText';
import SectionTitle from '/components/common/Section/SectionTitle';
import withAuthRequired from '/components/withAuthRequired';
import {
  ALERT_RED,
  DESKTOP_MAX_WIDTH,
  KEY_GRAY,
  KEY_LIGHT_GRAY,
} from '/constants';
import { useAuthContext } from '/context';
import { SurveyResponseInput, UserRole } from '/generated/graphql';
import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';

function DeleteAccount() {
  const { userData, deleteProfile } = useAuthContext();

  const [surveyResponse, setSurveyResponse] =
    React.useState<SurveyResponseInput>();
  const [understandsIsPermaanent, setUnderstandsIsPermanent] =
    React.useState(false);

  function onDeleteProfile() {
    deleteProfile(surveyResponse);
  }

  return (
    <View style={{ flex: 1, backgroundColor: 'white' }}>
      <KeyboardAwareScrollView
        style={DESKTOP_MAX_WIDTH}
        contentContainerStyle={styles.contentContainer}
      >
        <View style={styles.avatarContainer}>
          <Avatar
            rounded
            containerStyle={styles.avatar}
            avatarStyle={{
              opacity: 0.7,
            }}
            source={{ uri: userData?.profile_image }}
            size={128}
          />
          <MaterialCommunityIcons
            name="delete-forever"
            size={56}
            color={ALERT_RED}
            style={{
              position: 'absolute',
              right: 0,
              bottom: 0,
            }}
          />
        </View>
        <SectionTitle style={styles.name}>{userData?.name}</SectionTitle>

        <SectionContainer style={styles.overviewContainer}>
          <SectionText style={{ fontFamily: 'Lato-Bold' }}>
            Here's is an overview of what deleting your account will remove:
          </SectionText>

          <View style={styles.bulletPointsContainer}>
            <HorizontalContainer style={styles.bulletPoint}>
              <IconContainer>
                <FontAwesome name="user-circle-o" size={32} color="black" />
              </IconContainer>
              <SectionText style={styles.bulletPointText}>
                Your profile and associated content on the platform including
                comments, posts, likes, and other public or private content will
                be permanently deleted.
              </SectionText>
            </HorizontalContainer>

            <HorizontalContainer style={styles.bulletPoint}>
              <IconContainer>
                <MaterialIcons name="perm-media" size={32} color="black" />
              </IconContainer>
              <SectionText style={styles.bulletPointText}>
                All documents and media uploaded by you and associated with your
                account will be permanently deleted from our servers, except for
                documents you have shared with other users.
              </SectionText>
            </HorizontalContainer>

            <HorizontalContainer style={styles.bulletPoint}>
              <IconContainer>
                <MaterialIcons name="email" size={32} color="black" />
              </IconContainer>
              <SectionText style={styles.bulletPointText}>
                Your email address will be removed from our mailing list.
              </SectionText>
            </HorizontalContainer>

            {userData?.role === UserRole.Conservationist ? (
              <HorizontalContainer style={styles.bulletPoint}>
                <IconContainer icon="close-circle" color={ALERT_RED}>
                  <FontAwesome name="bank" size={32} color="black" />
                </IconContainer>
                <SectionText style={styles.bulletPointText}>
                  If you have an associated Stripe or other payment account, it
                  will remain active but will be disassociated from your
                  account. To delete a payment account, visit the payment
                  account provider.
                </SectionText>
              </HorizontalContainer>
            ) : null}
          </View>
        </SectionContainer>

        <DeleteAccountSurvey setSurveyResponse={setSurveyResponse} />

        <Pressable
          style={{ width: '100%' }}
          onPress={() => {
            setUnderstandsIsPermanent((v) => !v);
          }}
        >
          <SectionContainer style={styles.confirmBox}>
            <HorizontalContainer>
              <CheckmarkSwitch
                value={understandsIsPermaanent}
                onValueChange={setUnderstandsIsPermanent}
              />
              <SectionText style={styles.confirmBoxText}>
                I understand that deleting my account is permanent and cannot be
                undone.
              </SectionText>
            </HorizontalContainer>
          </SectionContainer>
        </Pressable>

        <Button
          label="Delete Account"
          disabled={!understandsIsPermaanent}
          style={{
            backgroundColor: ALERT_RED,
          }}
          labelStyle={{
            color: 'white',
          }}
          containerStyle={{
            marginTop: 8,
            alignSelf: 'flex-end',
          }}
          onPress={onDeleteProfile}
        />
      </KeyboardAwareScrollView>
    </View>
  );
}

function IconContainer({
  children,
  icon = 'checkmark-circle',
  color = KEY_GRAY,
}: React.PropsWithChildren<{
  icon?: 'checkmark-circle' | 'close-circle';
  color?: string;
}>) {
  return (
    <View>
      {children}
      <View style={styles.bulletPointCheckmark}>
        <Ionicons name={icon} size={16} color={color} />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: 'center',
    padding: 10,
  },
  avatarContainer: {
    alignSelf: 'center',
    marginTop: 18,
  },
  avatar: {
    backgroundColor: KEY_GRAY,
  },
  name: {
    fontFamily: 'Lato-Bold',
    fontSize: 28,
    marginVertical: 8,
    maxWidth: 400,
    textTransform: 'uppercase',
  },
  bulletPointsContainer: {
    paddingVertical: 8,
  },
  bulletPoint: {
    marginVertical: 4,
  },
  bulletPointText: { flex: 1, marginLeft: 8 },
  bulletPointCheckmark: {
    position: 'absolute',
    right: -2,
    bottom: -2,
    borderRadius: 10,
    width: 17,
    height: 17,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: KEY_LIGHT_GRAY,
    overflow: 'hidden',
  },
  overviewContainer: {
    backgroundColor: KEY_LIGHT_GRAY,
    marginHorizontal: 0,
  },
  confirmBox: {
    flex: 1,
    backgroundColor: ALERT_RED,
    marginHorizontal: 0,
  },
  confirmBoxText: {
    color: 'white',
    fontFamily: 'Lato-Bold',
    flex: 1,
    marginLeft: 10,
  },
});

export default withAuthRequired(DeleteAccount);
