export default {
  AboutUsImage: () => null,
  ActionableNotificationsSection: () => null,
  AppInfoResponse: () => null,
  CampaignImpactData: () => null,
  CampaignStatsResponse: () => null,
  CarouselCard: () => null,
  CCCollaboratorApplicationCustomQuestionResponse: () => null,
  CreativeConnectCollaborator: (data) => `${data.projectId}/${data.userId}`,
  CreativeConnectCustomSection: () => null,
  CreativeConnectCustomSectionCarouselCard: () => null,
  CreativeConnectOverallAnalyticsProject: (data) => data.project?.id,
  CreativeConnectOverallAnalyticsResponse: (data) => data.userId,
  CreativeConnectProjectAnalyticsResponse: () => null,
  CreativeConnectProjectCTAButton: () => null,
  CreativeConnectProjectPointOfContact: () => null,
  CurrencyAmount: () => null,
  DirectConversation: (data) => `${data.user1Id}/${data.user2Id}`,
  DiscoverCreativeConnectProjectsResponse: () => null,
  DiscoverMapGridCell: () => null,
  DiscoverMapGridCellTopResult: () => null,
  DiscoverMapGeohashResponse: (data) => data.geohash,
  DiscoverMapResponse: (data) => `${JSON.stringify(data.viewport)}`,
  DiscussionBoardParentResponse: () => null,
  EventAttendeeInvitationResponse: () => null,
  EventPartner: (data) => `${data.eventId}/${data.userId}`,
  GetAllNotificationsResponse: () => null,
  getRecentCampaigns: () => null,
  GroupMember: (data) => `${data.groupId}/${data.userId}`,
  JobPostBookmark: (data) => `${data.userId}/${data.jobPostId}`,
  LinkPreview: () => null,
  ListManageOrganizationCampaignsResponse: () => null,
  Location: () => null,
  MapBounds: () => null,
  MapCenter: () => null,
  MapChartData: () => null,
  MapViewport: () => null,
  MyEventsResponse: () => null,
  MyImpactApplicationsResponse: () => null,
  NewsPostBlock: () => null,
  NotificationPreferences: (data) => `${data.userId}`,
  OrganizationApplicationsPaginatedResponse: () => null,
  OrganizationBigIssue: () => null,
  OrganizationStatsCampaigns: () => null,
  OrganizationStatsFunding: () => null,
  OrganizationStatsResponse: () => null,
  OrganizationStatsSkilledImpact: () => null,
  OrganizationStatsTopSpecies: () => null,
  OrganizationStatsVolunteering: () => null,
  PaginatedBookmarks: () => null,
  PaginatedCampaigns: () => null,
  PaginatedComments: () => null,
  PaginatedCampaignConnectInvites: () => null,
  PaginatedCampaignPosts: () => null,
  PaginatedConnections: () => null,
  PaginatedCreativeConnectCollaborators: () => null,
  PaginatedCreativeConnectOverallAnalyticsProjects: () => null,
  PaginatedCreativeConnectProjects: () => null,
  PaginatedDirectConversations: () => null,
  PaginatedDirectMessages: () => null,
  PaginatedDonations: () => null,
  PaginatedEventAttendees: () => null,
  PaginatedEvents: () => null,
  PaginatedFollowers: () => null,
  PaginatedGroupDiscussionCategories: () => null,
  PaginatedGroupDiscussionPosts: () => null,
  PaginatedGroupMembers: () => null,
  PaginatedGroups: () => null,
  PaginatedJobPosts: () => null,
  PaginatedKeyBulletinPosts: () => null,
  PaginatedMessages: () => null,
  PaginatedMomentInNature: () => null,
  PaginatedNewComments: () => null,
  PaginatedNewsPosts: () => null,
  PaginatedOrganizationBigIssues: () => null,
  PaginatedPartnerships: () => null,
  PaginatedResearchTopics: () => null,
  PaginatedResearchTopicGroups: () => null,
  PaginatedSharedDocuments: () => null,
  PaginatedSkillRequestApplications: () => null,
  PaginatedSkills: () => null,
  PaginatedSpecies: () => null,
  PaginatedSpeciesUserContentMedia: () => null,
  PaginatedSpeciesUserContentFacts: () => null,
  PaginatedSpeciesVernacularNames: () => null,
  PaginatedSurveys: () => null,
  PaginatedSurveySubmissions: () => null,
  PaginatedSystemSurveys: () => null,
  PaginatedTeams: () => null,
  PaginatedTeamMembers: () => null,
  PaginatedUserReports: () => null,
  PaginatedUsers: () => null,
  PaginatedVolunteerRegistrations: () => null,
  ReferralLink: (data) => `${data.code}`,
  ResearchTopic: (data) => `${data.topic}`,
  Skill: (data) => `${data.name}`,
  SkillRequestExpertise: () => null,
  SkillRequestExpertiseResponse: () => null,
  SkillRequestGoal: () => null,
  Species: (data) => data.taxonID,
  SpeciesData: () => null,
  SpeciesIUCNThreatStatus: (data) => data.taxonID,
  SpeciesSearchResponse: () => null,
  SpeciesSearchResultHighlight: () => null,
  SpeciesSelectionResponse: () => null,
  SpeciesUserContent: (data) => `${data.userId}/${data.speciesTaxonID}`,
  SpeciesVernacularName: (data) => `${data.taxonID}/${data.vernacularName}`,
  SurveyResponse: (data) => `${data.submissionId}/${data.questionId}`,
  TeamMember: (data) => `${data.teamId}/${data.userId}`,
  TimeChartData: () => null,
  TitledLink: () => null,
  TranslatableText: () => null,
  UserImpactedCampaigns: () => null,
  UserReportReason: () => null,
  UserSkillPortfolio: (data) => `${data.userId}/${data.skillName}`,
  ViewingAsUserResponse: () => null,
  VolunteerRegistration: (data) => `${data.userId}/${data.volunteerRequestId}`,
  VolunteerRequestPointOfContact: () => null,
} as {
  [key: string]: (data: any) => string | null;
};
