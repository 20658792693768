import { useLinkTo } from '@react-navigation/native';
import { ResizeMode } from 'expo-av';
import { BlurView } from 'expo-blur';
import React, { useState } from 'react';
import {
  Animated,
  Image,
  StyleProp,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
  ViewStyle,
} from 'react-native';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
  minSize,
} from 'rn-responsive-styles';
import awsconfig from '/aws-exports';
import Button from '/components/Button';
import Collapsible from '/components/Collapsible';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import ScrollView from '/components/common/ScrollView/ScrollView';
import VideoPlayer from '/components/VideoPlayer';
import { KEY_GREEN, KEY_LIGHT_GRAY } from '/constants';
import { useAuthContext } from '/context';
import getCDNImageUri from '/util/getCDNImageUri';
import getCDNVideoUri from '/util/getCDNVideoUri';

export default function TesterOnlyModeLandingScreen() {
  const { styles } = useStyles();
  const linkTo = useLinkTo();

  const [suppressButterfulyVideo, setSuppressButterfulyVideo] = useState(false);

  const { user } = useAuthContext();

  const scrollY = React.useRef(new Animated.Value(0)).current;

  const birdsSectionRef = React.useRef<View>(null);
  const [birdsY, setBirdsY] = useState(0);

  // Measure positions after layout
  React.useEffect(() => {
    birdsSectionRef.current?.measureInWindow((x, y) => setBirdsY(y));
  }, []);

  return (
    <View
      style={[
        StyleSheet.absoluteFill,
        {
          backgroundColor: KEY_LIGHT_GRAY,
        },
      ]}
    >
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        onScroll={Animated.event(
          [{ nativeEvent: { contentOffset: { y: scrollY } } }],
          { useNativeDriver: true },
        )}
        scrollEventThrottle={16}
      >
        <FixedHeightSection
          style={{
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'black',
          }}
        >
          <View style={[StyleSheet.absoluteFill, { opacity: 0.85 }]}>
            <Animated.View
              style={[
                StyleSheet.absoluteFill,
                {
                  transform: [
                    {
                      translateY: scrollY.interpolate({
                        inputRange: [0, 400],
                        outputRange: [0, 100],
                        extrapolate: 'clamp',
                      }),
                    },
                  ],
                },
              ]}
            >
              <Image
                source={require('/assets/images/ExtremeCloseButterflyThumb.webp')}
                style={[StyleSheet.absoluteFill, { zIndex: -2 }]}
              />
              {suppressButterfulyVideo ? null : (
                <VideoPlayer
                  hideControls
                  hideBufferIndicator
                  isLooping={false}
                  resetOnEnd={false}
                  shouldPlay
                  isMuted
                  thumbnailResizeMode={ResizeMode.COVER}
                  onPlaybackStatusUpdate={(status) => {
                    if (status.isLoaded && status.didJustFinish) {
                      setSuppressButterfulyVideo(true);
                    }
                  }}
                  sourceUri={getTesterOnlyModeHeroVideoUri(
                    'ExtremeCloseBlueButterfly',
                    '720p',
                  )}
                  resizeMode={ResizeMode.COVER}
                  style={[
                    StyleSheet.absoluteFill,
                    {
                      zIndex: -1,
                      backgroundColor: 'black',
                    },
                  ]}
                />
              )}
            </Animated.View>
          </View>

          <View
            style={{
              flex: 1,
              alignSelf: 'flex-end',
              minWidth: 320,
              paddingHorizontal: 24,
              paddingRight: 32,
              width: '50%',
              justifyContent: 'center',
            }}
          >
            <Image
              source={require('/assets/images/keyFullWhite.png')}
              style={{
                width: 240,
                height: 240,
                margin: -36,
                marginBottom: -16,
              }}
            />

            <Text style={styles('heroText')}>
              Our{' '}
              <Text
                style={{
                  color: KEY_GREEN,
                }}
              >
                Next Phase
              </Text>
            </Text>
            <Text style={styles('heroText')}>Is Taking Flight</Text>
            <Text style={styles('heroSubtext')}>
              STRONGER TOOLS. BIGGER IMPACT. BOLDER ACTION.
            </Text>

            <View
              style={{
                padding: 10,
                paddingHorizontal: 24,
                marginTop: 32,
                marginBottom: 32,
                alignSelf: 'flex-start',
                backgroundColor: '#0ee6a6',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 8,
              }}
            >
              <Text style={styles('launchingSpring2025')}>
                LAUNCHING SPRING 2025
              </Text>
            </View>
          </View>
        </FixedHeightSection>

        <FixedHeightSection style={styles('backedBy1MSection')}>
          <HorizontalContainer
            style={styles('horizontalContainerIfLargerThanMediumReverse')}
          >
            <View style={styles('horizontalHalfContainer')}>
              <VideoPlayer
                style={[
                  styles('heartLagoonFlyover'),
                  {
                    borderRadius: 500,
                    overflow: 'hidden',
                    alignSelf: 'center',
                  },
                ]}
                hideBufferIndicator
                sourceUri={getTesterOnlyModeHeroVideoUri(
                  'HeartLagoonFlyover',
                  '480p',
                )}
                resizeMode={ResizeMode.COVER}
                thumbnailSource={{
                  uri: getTesterOnlyModeHeroVideoThumbnailUri(
                    'HeartLagoonFlyover',
                  ),
                }}
                hideControls
                isLooping
                shouldPlay
                isMuted
              />
            </View>

            <View
              style={[
                styles('horizontalHalfContainer'),
                {
                  alignItems: 'center',
                  justifyContent: 'center',
                  paddingHorizontal: 40,
                },
              ]}
            >
              <Text style={styles('sectionTitle')}>
                Backed by{' '}
                <Text
                  style={{
                    color: '#09bf78',
                  }}
                >
                  $1M in Funding
                </Text>{' '}
                From the National Science Foundation
              </Text>

              <Text style={styles('sectionText')}>
                We're embarking on a transformative journey to create a platform
                that empowers you to achieve even greater conservation impact.
                This exciting new chapter is all about growth, innovation, and
                providing the tools you need to make a difference like never
                before.
              </Text>

              <HorizontalContainer
                style={{
                  marginRight: 64,
                  alignSelf: 'flex-start',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  alignItems: 'flex-end',
                }}
              >
                <Image
                  source={require('/assets/images/nsf-logo.png')}
                  resizeMode="contain"
                  style={{
                    marginHorizontal: 12,
                    width: 120,
                    height: 120,
                    marginTop: 32,
                    marginBottom: 48,
                  }}
                />
                <Image
                  source={require('/assets/images/keyFullBlack.png')}
                  resizeMode="contain"
                  style={{
                    marginHorizontal: 12,
                    marginTop: 32,
                    width: 100,
                    height: 100,
                    marginBottom: 58,
                  }}
                />
                <View>
                  <Image
                    source={require('/assets/images/godwit-logo.png')}
                    resizeMode="contain"
                    style={{
                      marginHorizontal: -24,
                      width: 172,
                      height: 172,
                    }}
                  />
                  <Image
                    source={require('/assets/images/coming-soon-arrow.png')}
                    resizeMode="contain"
                    style={{
                      position: 'absolute',
                      right: -120,
                      top: 10,
                      width: 120,
                      height: 60,
                    }}
                  />
                </View>
              </HorizontalContainer>
            </View>
          </HorizontalContainer>
        </FixedHeightSection>

        <FixedHeightSection ref={birdsSectionRef}>
          <View style={StyleSheet.absoluteFill}>
            <Animated.View
              style={[
                StyleSheet.absoluteFill,
                {
                  transform: [
                    {
                      translateY: scrollY.interpolate({
                        inputRange: [birdsY - 400, birdsY, birdsY + 400],
                        outputRange: [-100, 0, 100],
                        extrapolate: 'clamp',
                      }),
                    },
                  ],
                },
              ]}
            >
              <VideoPlayer
                hideControls
                isLooping
                shouldPlay
                hideBufferIndicator
                isMuted
                mode="autoplay-viewport"
                thumbnailResizeMode={ResizeMode.COVER}
                thumbnailSource={{
                  uri: getTesterOnlyModeHeroVideoThumbnailUri('BirdsLanding'),
                }}
                sourceUri={getTesterOnlyModeHeroVideoUri(
                  'BirdsLanding',
                  '720p',
                )}
                resizeMode={ResizeMode.COVER}
                style={{
                  zIndex: -1,
                  width: '100%',
                  flex: 1,
                }}
              />
            </Animated.View>
          </View>

          <HorizontalContainer
            style={[
              styles('horizontalContainerIfLargerThanMedium'),
              {
                paddingBottom: 40,
              },
            ]}
          >
            <View style={styles('horizontalHalfContainer')}>
              <BlurView
                tint="systemThinMaterialDark"
                style={styles('darkGlassContainer')}
              >
                <View style={styles('darkGlassInnerContainer')}>
                  <Text
                    style={[
                      styles('sectionTitle'),
                      styles('fontSize48'),
                      {
                        color: 'white',
                      },
                    ]}
                  >
                    Get Involved
                  </Text>

                  <Text
                    style={[
                      styles('sectionText'),
                      {
                        color: 'white',
                      },
                    ]}
                  >
                    While we undergo a major overhaul and prepare to launch a
                    revolutionary way for conservation organizations to get
                    support like never before, we're inviting you to be part of
                    the transformation.{`\n\n`}
                    Just select Join Us below to get started.{' '}
                    <Text style={{ color: KEY_GREEN }}>
                      Already a member?
                    </Text>{' '}
                    Great, you will be able to log-in when you select Join Us
                    below.
                  </Text>

                  <Button
                    label="JOIN US"
                    onPress={() => {
                      if (user) linkTo('/tester-signup');
                      else linkTo('/login');
                    }}
                    containerStyle={{
                      marginTop: 32,
                    }}
                    style={{
                      backgroundColor: '#10d198',
                      paddingVertical: 12,
                    }}
                    labelStyle={{
                      fontSize: 19,
                    }}
                  />
                </View>
              </BlurView>
            </View>
            <View style={styles('horizontalHalfContainer')}>
              {/* <BlurView
                tint="systemThinMaterialDark"
                style={styles('darkGlassContainer')}
              >
                <View style={styles('darkGlassInnerContainer')}>
                  <Text
                    style={[
                      styles('sectionTitle'),
                      styles('fontSize48'),
                      { color: 'white' },
                    ]}
                  >
                    Sign In / Sign Up
                  </Text>

                  <Text
                    style={[
                      styles('sectionText'),
                      { color: 'white', marginBottom: 20 },
                    ]}
                  >
                    To learn more about the different ways you can get involved,
                    stay up to date on our re-launch, and get involved, sign in
                    using one of the options below.
                  </Text>

                  <AuthForm />
                </View>

                {isAuthenticating || fetching ? (
                  <LoadingSpinnerOverlay />
                ) : null}
              </BlurView> */}
            </View>
          </HorizontalContainer>
        </FixedHeightSection>

        <FixedHeightSection>
          <Text style={styles('frequentlyAskedQuestionsTitle')}>
            Frequently Asked{`\n`}Questions
          </Text>

          <View style={{ flex: 1, padding: 32, alignItems: 'center' }}>
            <Collapsible
              title="Some question?"
              titleStyle={styles('faqCollapsibleTitle')}
              style={styles('faqCollapsible')}
            >
              <Text
                style={[
                  styles('sectionText'),
                  {
                    paddingHorizontal: 8,
                    paddingBottom: 8,
                  },
                ]}
              >
                Some answer. Some answer. Some answer. Some answer. Some answer.
                Some answer. Some answer. Some answer. Some answer. Some answer.
              </Text>
            </Collapsible>

            <Collapsible
              title="Some other question?"
              titleStyle={styles('faqCollapsibleTitle')}
              collapsed
              style={styles('faqCollapsible')}
            >
              <Text
                style={[
                  styles('sectionText'),
                  {
                    paddingHorizontal: 8,
                    paddingBottom: 8,
                  },
                ]}
              >
                Some other answer. Some other answer. Some other answer. Some
                other answer. Some other answer. Some other answer. Some other
                answer. Some other answer. Some other answer.
              </Text>
            </Collapsible>
          </View>
        </FixedHeightSection>
      </ScrollView>
    </View>
  );
}

const useStyles = CreateResponsiveStyle(
  {
    fixedHeightSection: {
      width: '100%',
      overflow: 'hidden',
    },
    launchingSpring2025: {
      fontSize: 24,
      color: 'black',
      fontFamily: 'Lato-BoldItalic',
    },
    darkGlassContainer: {
      borderRadius: 6,
      maxWidth: 480,
      alignSelf: 'center',
      overflow: 'hidden',
      borderWidth: 1,
      borderColor: 'rgba(61,61,64,0.5)',
    },
    darkGlassInnerContainer: {
      flex: 1,
      padding: 32,
      backgroundColor: 'rgba(0,0,0,0.2)',
    },
    heroText: {
      fontSize: 56,
      color: 'white',
      fontFamily: 'FranklinGothic-Bold',
    },
    sectionTitle: {
      fontSize: 56,
      color: 'black',
      fontFamily: 'FranklinGothic-Bold',
    },
    fontSize48: {
      fontSize: 48,
    },
    sectionText: {
      flexShrink: 1,
      fontSize: 20,
      color: 'black',
      fontFamily: 'Lato',
    },
    heroSubtext: {
      fontSize: 18,
      color: 'white',
      fontFamily: 'Lato-Bold',
    },
    heartLagoonFlyover: {
      width: 540,
      height: 540,
    },
    faqCollapsible: {
      width: '100%',
      borderWidth: 0,
      maxWidth: 720,
    },
    faqCollapsibleTitle: {
      fontFamily: 'FranklinGothic-Bold',
      fontSize: 22,
    },
    frequentlyAskedQuestionsTitle: {
      fontSize: 56,
      marginTop: 48,
      textAlign: 'center',
      alignSelf: 'center',
      color: 'black',
      fontFamily: 'FranklinGothic-Bold',
    },
    horizontalHalfContainer: { padding: 32 },
    horizontalContainerIfLargerThanMedium: {
      flex: 1,
    },
    horizontalContainerIfLargerThanMediumReverse: {
      flex: 1,
      flexDirection: 'row-reverse',
    },
    backedBy1MSection: {
      paddingHorizontal: 40,
    },
  },
  {
    [minSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      horizontalHalfContainer: {
        flex: 1,
      },
    },
    [maxSize(DEVICE_SIZES.LARGE_DEVICE)]: {
      backedBy1MSection: {
        paddingHorizontal: 0,
      },
      heartLagoonFlyover: {
        width: 480,
        height: 480,
      },
    },
    [maxSize(DEVICE_SIZES.MEDIUM_DEVICE)]: {
      horizontalContainerIfLargerThanMedium: {
        flexDirection: 'column',
      },
      horizontalContainerIfLargerThanMediumReverse: {
        flexDirection: 'column',
      },
      heartLagoonFlyover: {
        width: 300,
        height: 300,
      },
      horizontalHalfContainer: {
        flexShrink: 1,
        padding: 16,
      },
    },
  },
);

const FixedHeightSection = React.forwardRef<
  View,
  React.PropsWithChildren<{
    style?: StyleProp<ViewStyle>;
  }>
>((props, ref) => {
  const windowDimensions = useWindowDimensions();
  const { styles } = useStyles();

  return (
    <View
      ref={ref}
      style={[
        styles('fixedHeightSection'),
        props.style,
        {
          minHeight: windowDimensions.height,
        },
      ]}
    >
      {props.children}
    </View>
  );
});

type TesterOnlyModeHeroVideo =
  | 'AntarcticPenguinJump'
  | 'BirdsLanding'
  | 'ExtremeCloseBlueButterfly'
  | 'HeartLagoonFlyover'
  | 'PenguinGroupSwim';

function getTesterOnlyModeHeroVideoUri(
  video: TesterOnlyModeHeroVideo,
  preset: Parameters<typeof getCDNVideoUri>[0]['preset'] = '1080p',
) {
  return getCDNVideoUri({
    preset,
    uri: `https://${awsconfig.aws_user_files_s3_bucket}.s3.${awsconfig.aws_user_files_s3_bucket_region}.amazonaws.com/public/_allUsers/mp4/${video}.mp4`,
  });
}

function getTesterOnlyModeHeroVideoThumbnailUri(
  video: TesterOnlyModeHeroVideo,
) {
  return getCDNImageUri({
    uri: `https://${awsconfig.aws_user_files_s3_bucket}.s3.${awsconfig.aws_user_files_s3_bucket_region}.amazonaws.com/public/_allUsers/mp4/${video}.mp4`,
    isThumbnail: true,
  });
}
