import React from 'react';
import { ActivityIndicator, TextInput, View } from 'react-native';
import SectionText from '/components/common/Section/SectionText';
import { KEY_GRAY, TEXT_INPUT } from '/constants';
import {
  SystemSurveyPlacement,
  SurveyResponseInput,
  useGetSystemSurveyQuery,
} from '/generated/graphql';

type Props = {
  setSurveyResponse: (response: SurveyResponseInput) => void;
};

export default function DeleteAccountSurvey(props: Props) {
  const [{ data, fetching }] = useGetSystemSurveyQuery({
    variables: {
      placement: SystemSurveyPlacement.DeleteAccount,
      submissionsLimit: 0,
    },
  });

  const question = data?.getSystemSurvey.survey?.questions[0];

  return fetching ? (
    <ActivityIndicator
      size={'small'}
      color={KEY_GRAY}
      style={{
        alignSelf: 'center',
        padding: 10,
      }}
    />
  ) : question ? (
    <>
      <SectionText
        style={{
          marginVertical: 8,
        }}
      >
        {question.question}
      </SectionText>
      <View style={{ width: '100%' }}>
        <TextInput
          placeholderTextColor={'gray'}
          placeholder="(optional) Provide feedback..."
          style={TEXT_INPUT}
          onChangeText={(text) => {
            props.setSurveyResponse({
              questionId: question.id,
              response: text,
            });
          }}
        />
      </View>
    </>
  ) : null;
}
