import { NativeScrollEvent, Platform, Linking } from 'react-native';
import env from '/env';
import {
  CampaignUrgency,
  MeQuery,
  TeamMemberRole,
  User,
} from '/generated/graphql';
import { DeepPartial } from '/types';
import * as Device from 'expo-device';
import * as Localization from 'expo-localization';
import Constants from 'expo-constants';
import dayjs from 'dayjs';
// @ts-ignore
import { getCountryByAbbreviation, getCountry } from 'country-currency-map';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Currency, getDecimalPlacesForCurrency } from 'currency-decimal-places';
import CurrencyToCountryMap from '/assets/json/CurrencyCountries.json';
import validator from 'validator';
import { CombinedError } from 'urql';
import { AuthUser, FetchUserAttributesOutput } from 'aws-amplify/auth';
import { isDate } from 'lodash';
import { URGENCY_COLORS } from '/constants';
import { Buffer } from 'buffer';

export { geocode } from './mapping/geocode';

// This file is for all kinds of miscellaneous helper functions & components
// that could be useful throughout the entire app, even if just in two places

// If you see any repetitve helper functions that would fit into here,
// add it. If you write a function that you think has a general enough
// purpose to go here, add it.

// Shorten returns a shortened string truncated by charLimit, and an ellipse
// is added to the end if the text gets trimmed at all
export const shorten = (text: string, charLimit: number) => {
  if (!text) return '';
  else if (text.length > charLimit) {
    let end = charLimit - 3;
    const avoidChars = [' ', ',', '.', '!', '/', '-', '(', '[', '{'];
    while (avoidChars.includes(text.charAt(end)) && end >= charLimit - 10) {
      end--;
    }
    return `${text.substring(0, end)}...`;
  } else return text;
};

export const isStrongPassword = (password: string) => {
  const passwordRegex = new RegExp(
    // Password is valid if it has:
    '^((?!.*[\\s])' + // No spaces
      '(((?=.*[A-Z])(?=.*[a-z])(?=.*\\d))|' + // 1 uppercase, 1 lowercase, 1 number
      '((?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]))|' + // OR 1 uppercase, 1 lowercase, 1 symbol
      '((?=.*[A-Z])(?=.*\\d)(?=.*[^A-Za-z0-9]))|' + // OR 1 upperacse, 1 symbol, 1 number
      '((?=.*[a-z])(?=.*\\d)(?=.*[^A-Za-z0-9])))' + // OR 1 lowercase, 1 symbol, 1 number
      '(?=.{8,}))', // At least 8 characters
    'g',
  );

  return passwordRegex.test(password);
};

export const shortenEmail = (email: string, charLimit: number) => {
  if (!email) return '';
  else if (email.length > charLimit) {
    const splitEmail = email.split('@');

    // Validate e-mail
    if (splitEmail.length !== 2) {
      console.warn(
        'Tried to shorten an email (' +
          email +
          '), but it does not look like an e-mail!',
      );
      return email;
    }

    let [username, domain] = splitEmail;

    // We use the Math.max function to prevent the username
    // from being less than 4 chars short (including 3 chars for the ellipse)
    const targetUsernameLength = Math.max(charLimit - domain.length, 4);
    const usernameLength = username.length;

    // We want to shorten the e-mail and replace with ellipses,
    // but we want to maintain the last character of the
    // left side of the e-mail (Because I like it That Way(TM))

    username =
      shorten(username.substring(0, usernameLength - 1), targetUsernameLength) +
      username.charAt(usernameLength - 1);

    return `${username}@${domain}`;
  } else return email;
};

// Initially in use in Volunteer widget to let users know if they
// are close to a volunteer opportunity by measuring how far
// away they are
/**
 * Measure the distance between two locations on earth
 * and return it in kilometers
 * @param lat1 Point 1 latitude
 * @param lon1 Point 1 longitude
 * @param lat2 Point 2 latitude
 * @param lon2 Point 2 longitude
 */
export function measureDistanceBetweenCoordinates(
  lat1: string | number,
  lon1: string | number,
  lat2: string | number,
  lon2: string | number,
) {
  lat1 = Number(lat1);
  lat2 = Number(lat2);
  lon1 = Number(lon1);
  lon2 = Number(lon2);

  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

// A function to take a duration in seconds and convert it
// to a timer string of format hh:mm:ss, rendering only
// mm:ss if the timer is shorter than an hour. Initially
// in use in places like the ImagePicker on video tiles.

/**
 * Takes duration in seconds and returns a string formatted
 * hh:mm:ss, truncating to mm:ss if duration is shorter than
 * an hour.
 * @param timeInSeconds Duration in seconds
 */
export function toTimerString(timeInSeconds: number): string {
  const format = (n: number) => (n < 10 ? `0${n}` : `${n}`);

  const hours = Math.floor(timeInSeconds / 3600);
  const minutes = Math.floor((timeInSeconds / 60) % 60);
  const seconds = Math.min(Math.round(timeInSeconds % 60), 59);

  if (hours > 0)
    return format(hours) + ':' + format(minutes) + ':' + format(seconds);
  else return format(minutes) + ':' + format(seconds);
}

/**
 * A function that formats currency strings.
 * Found at https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-string
 * @param amount The actual number to be formatted
 * @param decimalCount Decimal places to truncate to. Default is 2.
 * @param decimal Symbol to use as decimal. Default is '.'
 * @param thousands Symbol to use for thousands. Default is ','
 */
export function formatMoney(
  amount: number,
  decimalCount = 2,
  decimal = '.',
  thousands = ',',
) {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? '-' : '';

    let i = parseInt(
      Math.abs(Number(amount) || 0).toFixed(decimalCount),
    ).toString();
    let j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substring(0, j) + thousands : '') +
      i.substring(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - Number(i))
            .toFixed(decimalCount)
            .slice(2)
        : '')
    );
  } catch (e) {
    console.log(e);
  }
}

/**
 * Checks against a regular expression that validates URLs.
 * @param url URL to test against Regex
 * @param domain If set, regex will only match if `url` is a valid link to specified `domain`
 * @returns A boolean
 */
export const isValidURLRegex = (url: string | undefined, domain?: string) => {
  if (!url) return false;

  const isValidDomain = '(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}';

  const domainRegex = new RegExp(isValidDomain).test(domain || '')
    ? `(([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)*(${domain!.replace('.', '\\.')})` // Must end with value of `domain`. Subdomains included.
    : isValidDomain;

  const isValidIPv4 = new RegExp(isValidDomain).test(domain || '')
    ? '' // If we are matching against a specific domain, we don't want to consider any ipv4 address valid
    : '|((\\d{1,3}\\.){3}\\d{1,3})';

  return new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      `(${domainRegex}` + // domain name
      `${isValidIPv4})` + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d\\(\\)%_.~+:@]*)*' + // port and path
      '(\\?[;&a-z\\d\\(\\)%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ).test(encodeURI(url)); // Encode URI first to prevent illegal characters from breaking regex
};

/**
 * Checks against a regular expression that validates emails.
 * @param email An email to test against regex
 * @returns A boolean
 */
export const isValidEmail = (email: string) => {
  return !!email && validator.isEmail(email);
};

/**
 * Checks against a regular expression that matches strings that can be valid usernames
 * on popular social media sites (ie twitter, instagram, ...)
 * @param text Username
 * @returns Boolean - True if valid, false otherwise */
export const isValidUsernameRegex = (text: string) =>
  /^[a-zA-Z0-9_.]+$/.test(text);

export const isNumberRegex = (input: string) => {
  return /^[0-9]*$/gi.test(input);
};

/**
 * A function to format multiple URLs on an object
 * @param object Object containing URLS to be formatted
 * @param keys An array of keys to format
 */
export const formatURLs = (object: any, keys: string[]) => {
  if (object)
    keys.forEach((key) => {
      object[key] = formatURL(object[key]);
    });
  return object;
};

export const formatURL = (url: string) => {
  let formatted = url?.trim() || url;

  if (
    formatted &&
    formatted !== null &&
    formatted.indexOf('http://') !== 0 &&
    formatted.indexOf('https://') !== 0
  ) {
    formatted = 'https://' + url;
  }

  return formatted;
};

/**
 * A function that truncates numbers. Example: truncateNumber(500, 250) => '250+'
 * @param number Number to be truncated
 * @param max Max value of number. If number is more than max, it
 * will be truncated
 */
export const truncateNumber = (number: number, max: number): string => {
  if (number > max) return `${max}+`;
  else return `${number}`;
};

export const createUniversalURL = (path: string, queryParams?: any): string => {
  let query = '';

  // build query string only if queryParams was provided
  if (typeof queryParams === 'object') {
    Object.keys(queryParams).forEach((key, index) => {
      // If this is the first query parameter, prefix it with '?'
      if (index === 0) {
        query += '?';
      }
      // If not, prefix it with '&'
      else query += '&';

      // Add formatted & encoded parameter to query string
      query += `${key}=${encodeURIComponent(queryParams[key])}`;
    });
  }

  const forwardSlash = path.startsWith('/') ? '' : '/';

  return `${env.WEB_APP_URL}${forwardSlash}${path}${query}`;
};

export const createDeepLink = (path: string, queryParams?: any): string => {
  let query = '';

  // build query string only if queryParams was provided
  if (typeof queryParams === 'object') {
    Object.keys(queryParams).forEach((key, index) => {
      // If this is the first query parameter, prefix it with '?'
      if (index === 0) {
        query += '?';
      }
      // If not, prefix it with '&'
      else query += '&';

      // Add formatted & encoded parameter to query string
      query += encodeURIComponent(`${key}=${queryParams[key]}`);
    });
  }

  // @ts-ignore - hostUri not typed, but documented here: https://docs.expo.dev/versions/latest/sdk/constants/#nativeconstants
  return `${Constants.expoConfig?.scheme}://${Constants.expoConfig?.hostUri}/${path}${query}`;
};

/**
 * Determines whether a source uri or filename is that of a video file. Note:
 * This function checks against a non-comprehensive set of video file extensions,
 * @param filename Source URI or filename
 * @returns True if file extension is that of a video, false otherwise.
 */
export const determineIfVideo = (filename: string): boolean => {
  if (typeof filename !== 'string') return false;

  const EXPECTED_VIDEO_FORMATS = [
    'mov',
    'mp4',
    'mkv',
    'qt',
    'avi',
    'wmv',
    'flv',
  ];

  const split = filename?.split('.');
  const ext = split?.[split.length - 1].toLowerCase();

  return EXPECTED_VIDEO_FORMATS.includes(ext);
};

/** Recursive function that check if an object contains
 * any meaningful data
 *
 * @param ignoreFalsyValues If this is true, and a value is present but is falsy,
 * it will be ignored as if there is nothing there. Default is false.
 */
export function isEmpty(object: any, ignoreFalsyValues = false) {
  if (object === undefined || object === null) return true;

  if (
    typeof object === 'number' ||
    typeof object === 'boolean' ||
    isDate(object) // Dates don't have enumerable properties, so we need to check them separately
  )
    return false;

  // If this is an iterable that is not a string, recurse
  if (
    typeof object !== 'string' &&
    typeof object[Symbol.iterator] === 'function'
  ) {
    for (const item of object) {
      if (!isEmpty(item, ignoreFalsyValues)) return false;
    }
    return true;
  }
  // If item is not an iterable but is an object,
  // recurse (but using `for in` instead of `for of`)
  else if (typeof object === 'object') {
    for (const key in object) {
      if (!isEmpty(object[key], ignoreFalsyValues)) return false;
    }
    return true;
  }

  // If this is not an iterable nor an object,
  // then this qualifies as a non-empty change
  // If ignoreFalsyValues is set, then a non-empty
  // change no longer includes falsy values.
  return ignoreFalsyValues ? !object : false;
}

/**
 * This function takes a NativeScrollEvent and checks if current
 * scroll position is 48px or closer to the maximum, or in other words,
 * returns true if we are close to the bottom of the scroll vioe
 */
export const isCloseToBottom = ({
  layoutMeasurement,
  contentOffset,
  contentSize,
}: NativeScrollEvent) => {
  const paddingToBottom = 48;
  return (
    layoutMeasurement.height + contentOffset.y >=
    contentSize.height - paddingToBottom
  );
};

/**
 * Checks if TeamMemberRole meets or exceeds a required role
 * @param requiredRole Minimum role required
 * @param actualRole Role to check against
 * @returns A boolean. True if actualRole meets or exceeds requiredRole, false otherwise.
 */
export const isUnderPrivileged = (
  requiredRole: TeamMemberRole,
  actualRole: TeamMemberRole | null | undefined,
) => {
  if (
    requiredRole === TeamMemberRole.Admin &&
    actualRole !== TeamMemberRole.Admin
  ) {
    return true;
  }

  if (
    requiredRole === TeamMemberRole.Creator &&
    actualRole !== TeamMemberRole.Admin &&
    actualRole !== TeamMemberRole.Creator
  ) {
    return true;
  }

  return false;
};

// Inner helper function that returns an array of booleans, each corresponding
// to a profile requirement
const getOrganizationProfileRequirementsArray = (
  profile: Pick<DeepPartial<User>, 'bio_translatable' | 'cover_image'>,
) => {
  /** Define requirements */
  const requirements = [];

  /** Make sure profile has... */
  /** Bio (or "About Us") */
  requirements.push(!!profile.bio_translatable?.text?.trim());
  /** Cover media */
  requirements.push(!!profile.cover_image);

  return requirements;
};

const getSupporterProfileRequirementsArray = (
  profile: Pick<
    DeepPartial<User>,
    'bio_translatable' | 'profile_image' | 'skills' | 'cover_image'
  >,
) => {
  /** Define requirements */
  const requirements: boolean[] = [];

  /** Make sure profile has... */
  /** Profile photo */
  requirements.push(!!profile.profile_image);
  /** Bio (or "About Us") */
  requirements.push(!!profile.bio_translatable?.text?.trim());
  /** Cover media */
  requirements.push(!!profile.cover_image);
  /** Skills */
  requirements.push(!!profile.skills?.length);

  return requirements;
};

export const isOrganizationProfileComplete = (
  profile: Pick<
    NonNullable<MeQuery['me']>,
    'is_verified' | 'bio_translatable' | 'cover_image'
  >,
) => {
  if (!profile.is_verified) return false;

  const requirements = getOrganizationProfileRequirementsArray(profile);

  return requirements.every((requirement) => requirement === true);
};

export const isSupporterProfileComplete = (
  profile: Pick<DeepPartial<User>, 'bio' | 'skills' | 'cover_image'>,
) => {
  const requirements = getSupporterProfileRequirementsArray(profile);

  return requirements.every((requirement) => requirement === true);
};

export const getOrganizationProfileCompletionProgress = (
  profile: Pick<DeepPartial<User>, 'bio' | 'cover_image'> | undefined,
) => {
  if (!profile) return 100;

  const requirements = getOrganizationProfileRequirementsArray(profile);

  const completedCount = requirements.filter((r) => r).length;

  return Math.ceil((completedCount / requirements.length) * 100);
};

export const getSupporterProfileCompletionProgress = (
  profile:
    | Pick<DeepPartial<User>, 'bio' | 'skills' | 'cover_image'>
    | undefined,
) => {
  if (!profile) return 100;

  const requirements = getSupporterProfileRequirementsArray(profile);

  const completedCount = requirements.filter((r) => r).length;

  return Math.ceil((completedCount / requirements.length) * 100);
};

/** Checks useragent to determine if user is using a mobile device or tablet to determine where they are
 * using a device that uses a mouse/trackpad */
export const isPointerDevice = () => {
  if (Platform.OS !== 'web') return false;

  let isMobileOrTablet = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a,
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
        a.substr(0, 4),
      )
    )
      isMobileOrTablet = true;
    // @ts-ignore
  })(navigator.userAgent || navigator.vendor || window.opera);

  return !isMobileOrTablet;
};

export const openFeedbackForm = (
  userSub: string | undefined,
  userData: Pick<User, 'email'> | undefined,
) => {
  const PhoneOS = Device.osName;
  const AppVersion = Constants.expoConfig?.version;
  const AuthenticatedUserId = `${userSub}`;

  console.log('AppVersion', AppVersion);

  const FORM_URL = 'https://airtable.com/shrjkMv58D3zICeWN';

  let url =
    FORM_URL +
    `?prefill_OS=${PhoneOS}&hide_OS=true` +
    `&prefill_AuthenticatedUserId=${AuthenticatedUserId}&hide_AuthenticatedUserId=true` +
    `&prefill_AppVersion=${AppVersion}&hide_AppVersion=true` +
    (userData?.email ? `&prefill_Email=${userData?.email}` : '');

  // @ts-ignore
  Linking.openURL(url, '_blank');
};

export const getCurrencyFormatter = (currency: string) => {
  const decimalPlaces = getDecimalPlacesForCurrency(
    currency.toUpperCase() as Currency,
  );

  return Intl.NumberFormat(Localization.locale, {
    currency,
    style: 'currency',
    minimumSignificantDigits: 1,
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
};

/** Returns the currency that should be used for this device */
export const getLocaleCurrencyCode = (): Currency => {
  // @ts-ignore
  const locale = Localization.getLocales()[0];

  let currency = locale?.currencyCode as Currency;

  if (!currency) {
    const regionCode = locale.regionCode;
    const countryName = regionCode && getCountryByAbbreviation(regionCode);
    const country = countryName && getCountry(countryName.toUpperCase());
    if (country?.currency) {
      currency = country.currency;
    }
  }

  if (!currency) {
    currency = 'USD' as Currency;
  }

  return currency;
};

/** Returns the currency symbol that should be used on this device */
export const getLocaleCurrencySymbol = () => {
  const currencyCode = getLocaleCurrencyCode();
  return getSymbolFromCurrency(currencyCode) || currencyCode;
};

export function convertMoneyToMinorUnit(amount: number, currency: Currency) {
  // Get the number of decimal places for the currency
  const decimals = getDecimalPlacesForCurrency(currency);

  // Convert the amount in major units to minor units
  const minorUnitAmount = Math.round(amount * Math.pow(10, decimals ?? 0));

  return minorUnitAmount;
}

export function getCountryForCurrencyCode(
  currency: keyof typeof CurrencyToCountryMap,
) {
  return CurrencyToCountryMap[
    currency.toUpperCase() as keyof typeof CurrencyToCountryMap
  ];
}

export function removeTypename<ObjectT = any>(data: ObjectT): ObjectT {
  if (!data || typeof data !== 'object') {
    return data;
  }

  return Object.keys(data).reduce(
    function (acc, key) {
      var value = data[key as keyof typeof data];

      if (key === '__typename') {
        delete acc[key];
      } else if (Array.isArray(value)) {
        acc[key] = value.map(removeTypename);
      } else if (value && typeof value === 'object' && '__typename' in value) {
        acc[key] = removeTypename(value);
      } else {
        acc[key] = value;
      }

      return acc;
    },
    Array.isArray(data) ? [] : ({} as any),
  ) as ObjectT;
}

export function isValidJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function extractDomain(url: string) {
  const match = url.match(/^(?:https?:\/\/)?(?:www\.)?([^/]+)/i);
  return match ? match[1] : null;
}

export function getShortRelativeTime(time: Date | string | number) {
  const relative = dayjs().to(dayjs(time));

  if (relative.includes('second')) {
    return relative
      .replace('a few seconds ago', 'just now')
      .replace(/ seconds? ago/, 's ago');
  }

  if (relative.includes('minute')) {
    return relative
      .replace('a minute ago', '1m ago')
      .replace(/ minutes? ago/, 'm ago');
  }

  if (relative.includes('hour')) {
    return relative
      .replace('an hour ago', '1h ago')
      .replace(/ hours? ago/, 'h ago');
  }

  if (relative.includes('day')) {
    return relative
      .replace('a day ago', '1d ago')
      .replace(/ days? ago/, 'd ago');
  }

  if (relative.includes('month')) {
    return relative
      .replace('a month ago', '1mo ago')
      .replace(/ months? ago/, 'mo ago');
  }

  if (relative.includes('year')) {
    return relative
      .replace('a year ago', '1y ago')
      .replace(/ years? ago/, 'y ago');
  }

  // Return original relative time if no match
  return relative;
}

export function isAuthError(error: CombinedError | undefined) {
  return !!error?.message?.includes(
    'You need to be authorized to perform this action',
  );
}

export function snakeToPascal(snake_str: string) {
  return snake_str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
}

export function pascalToSnake(pascal_str: string) {
  return pascal_str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
}

export function canChangeEmailAndPassword(
  user: AuthUser | undefined,
  userAttributes: FetchUserAttributesOutput | undefined,
) {
  return (
    !!user?.signInDetails ||
    typeof userAttributes?.['custom:has_auto_password'] === 'string'
  );
}

export function userHasPassword(
  user: AuthUser | undefined,
  userAttributes: FetchUserAttributesOutput | undefined,
) {
  /**
   * Some accounts were created before the 'has_auto_password' attribute was added.
   * For those accounts, we'll check if the user has a signInDetails object.
   */
  return (
    !!user?.signInDetails ||
    userAttributes?.['custom:has_auto_password'] === 'false'
  );
}

export function findFirstValidURL(text: string) {
  // Split the text into words
  const words = text.split(/\s+/);

  // Iterate over the words
  for (const word of words) {
    // If the word is a valid URL, return it
    if (validator.isURL(word)) {
      return word;
    }
  }

  // If no valid URL was found, return null
  return null;
}

/** Filters out any instances of null, undefined, empty strings and strings that only contain spaces */
export function filterEmptyValues<T extends { [key: string]: any }>(
  obj: T,
): Partial<T> {
  return Object.fromEntries(
    Object.entries(obj).filter(([, v]) => {
      if (typeof v === 'string') return !!v.trim();
      else return !!v;
    }),
  ) as Partial<T>;
}

export function parseBoolean(value: any): boolean {
  if (typeof value === 'boolean') {
    return value;
  }
  if (typeof value === 'string') {
    return value.toLowerCase() === 'true';
  }
  return false;
}

const URGENCY_VALUES: {
  [key: string]: {
    color: string;
    label: string;
    labelColor?: string;
  };
} = {
  [CampaignUrgency.Critical]: {
    color: URGENCY_COLORS.CRITICAL,
    label: 'CRITICAL',
    labelColor: 'white',
  },
  [CampaignUrgency.LongTerm]: {
    color: URGENCY_COLORS.LONG_TERM,
    label: 'LONG-TERM',
  },
  [CampaignUrgency.Urgent]: { color: URGENCY_COLORS.URGENT, label: 'URGENT' },
  [CampaignUrgency.None]: { color: 'transparent', label: '' },
};
export function getUrgencyLabelAndColors(
  urgency: CampaignUrgency | undefined,
): (typeof URGENCY_VALUES)[string] {
  return URGENCY_VALUES[urgency || CampaignUrgency.None];
}

export function base64EncodeObject(obj: any) {
  return Buffer.from(JSON.stringify(obj), 'ascii').toString('base64');
}

export function base64DecodeObject(str: string) {
  try {
    return JSON.parse(Buffer.from(str, 'base64').toString('ascii'));
  } catch (e) {
    console.error('Error decoding base64 encoded object', e);
    return null;
  }
}

// Add more above this line...
