import { StyleSheet } from 'react-native';
import { KEY_GRAY, TITLE_FONT_SIZE } from '/constants';

export default StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: 'transparent',
    borderRadius: 8,
    overflow: 'hidden',
    maxHeight: 400,
  },
  formContainer: {
    // marginTop: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loading: {
    zIndex: 2,
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'white',
  },
  headerSection: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 16,
    marginHorizontal: 48,
  },
  backButton: {
    padding: 8,
    paddingRight: 16,
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: TITLE_FONT_SIZE,
  },
  tabSelector: {
    flexDirection: 'row',
    maxHeight: 50,
    backgroundColor: 'transparent',
    shadowColor: 'gray',
    shadowRadius: 2,
    shadowOpacity: 0.3,
    shadowOffset: {
      height: 2,
      width: 0,
    },
    marginTop: '30%',
    width: '80%',
  },
  tabContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  tabWrapper: {
    width: '100%',
    alignItems: 'center',
  },
  tab: {
    paddingTop: 10,
    height: '100%',
    alignItems: 'center',
  },
  instructionTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  instructionText: {
    flex: 1,
    paddingBottom: 10,
    color: '#fafafa',
    fontSize: 19,
    fontFamily: 'Lato-Bold',
    alignSelf: 'flex-start',
  },
  acceptsTermsText: {
    flex: 1,
    color: KEY_GRAY,
    fontSize: 15,
    fontFamily: 'Lato',
  },
  acceptsTermsTextBold: {
    fontFamily: 'Lato-Bold',
  },
  selectedTab: {
    width: '100%',
    backgroundColor: '#D3F64A',
    height: 3,
  },
  formContent: {
    // padding: 16,
    // paddingTop: 0,
    width: '100%',
    // backgroundColor: 'blue',
  },
  footnoteContainer: {
    marginVertical: 4,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  footnote: {
    marginTop: 2,
    paddingVertical: 10,
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: '#fafafa',
  },
  testerOnlyModeContainer: {
    marginBottom: 16,
    backgroundColor: 'white',
    padding: 8,
    borderRadius: 6,
  },
  testerOnlyModeText: {
    fontFamily: 'Lato-Bold',
    fontSize: 15,
    color: '#333',
  },
});
