import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    borderColor: 'gray',
    borderRadius: 8,
    marginVertical: 12,
    overflow: 'hidden',
  },
  title_bar: {
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 8,
  },
  title: {
    padding: 10,
    fontWeight: 'bold',
    fontSize: 16,
    flexShrink: 1,
  },
  arrow: {
    flex: 1,
  },
  arrowContainer: {
    width: 30,
    height: 30,
    alignItems: 'center',
  },
  content: {
    padding: 8,
    paddingTop: 8,
    overflow: 'hidden',
  },
});

export default styles;
