import { UpdateResolver } from '@urql/exchange-graphcache';
import { gql } from 'urql';
import { SpeciesUserContentFragmentFragmentDoc } from '/generated/graphql';

const UserProfileFragment = gql`
  fragment DeleteSpeciesUserContentUserProfile on User {
    id
    main_species {
      ...SpeciesUserContentFragment
    }
    secondary_species {
      ...SpeciesUserContentFragment
    }
  }
  ${SpeciesUserContentFragmentFragmentDoc}
`;

const deleteSpeciesUserContent: UpdateResolver = (result, args, cache) => {
  cache.invalidate({
    __typename: 'SpeciesUserContent',
    userId: args.userId,
    taxonID: args.taxonID,
  });

  const data: any = cache.readFragment(UserProfileFragment, {
    __typename: 'User',
    id: args.userId,
  });

  if (!data) return;

  cache.writeFragment(UserProfileFragment, {
    ...data,
    __typename: 'User',
    id: args.userId,
    main_species: [
      ...(data?.main_species ?? []).filter(
        (s: any) => s.speciesTaxonID !== args.taxonID,
      ),
    ],
    secondary_species: [
      ...(data?.secondary_species ?? []).filter(
        (s: any) => s.speciesTaxonID !== args.taxonID,
      ),
    ],
  });
};

export default deleteSpeciesUserContent;
