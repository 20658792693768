import { createStackNavigator } from '@react-navigation/stack';
import {
  DeleteAccountScreenOptions,
  getStackNavigatorOptions,
  NotificationPreferencesScreenOptions,
} from './nested/common';
import DeleteAccount from '/screens/DeleteAccount/DeleteAccount';
import NotificationPreferences from '/screens/NotificationPreferences/NotificationPreferences';
import TesterDashboard from '/screens/TesterOnlyMode/TesterDashboard';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

const Stack = createStackNavigator();

export default function TesterSignupStack() {
  const safeAreaInsets = useSafeAreaInsets();

  return (
    <Stack.Navigator
      screenOptions={getStackNavigatorOptions(safeAreaInsets)}
      initialRouteName="TesterDashboard"
    >
      <Stack.Screen
        name="TesterDashboard"
        component={TesterDashboard}
        options={{
          headerShown: false,
        }}
      />
      <Stack.Screen
        component={NotificationPreferences}
        name="NotificationPreferences"
        options={NotificationPreferencesScreenOptions}
      />
      <Stack.Screen
        component={DeleteAccount}
        name="DeleteAccount"
        options={DeleteAccountScreenOptions}
      />
    </Stack.Navigator>
  );
}
