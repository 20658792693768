import {
  KeyboardAwareFlatList,
  KeyboardAwareScrollView,
} from '@mtourj/react-native-keyboard-aware-scroll-view';
import { useNavigation } from '@react-navigation/native';
import { StackNavigationProp } from '@react-navigation/stack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ActivityIndicator,
  Keyboard,
  NativeScrollEvent,
  NativeSyntheticEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native';
import Button from '../Button';
import IUCNStatusImage from '../IUCNStatusImage/IUCNStatusImage';
import commonStyles from './common/styles';
import { KEY_GRAY } from '/constants';
import {
  SearchSpeciesQuery,
  SystemSurveyPlacement,
  useSearchSpeciesQuery,
} from '/generated/graphql';
import { isCloseToBottom } from '/util';
import GenericListFooter from '../GenericListFooter';
import GenericError from '../common/Generic/GenericError';

export interface SpeciesSearchSpecies
  extends Omit<SearchSpeciesQuery['searchSpecies']['species'][0], 'id'> {}

interface Props {
  query: string;
  style?: StyleProp<ViewStyle>;
  /** Default is `50` */
  resultsPerPage?: number;
  /** If true, ScrollView will be used instead of FlatList */
  useScrollView?: boolean;
  /** If true, the "Suggest a species" button will not be shown */
  hideSuggestButton?: boolean;
  /** Called when the user presses the "Suggest a species" button */
  onPressSuggestSpecies?: () => void;
  onLoadStart?: () => void;
  /** Called with the total number of species in the database when fetching is done*/
  onLoadEnd?: () => void;
  onSelectSpecies: (species: SpeciesSearchSpecies, name: string) => void;
  /** Will not make requests to the server if query is empoty. Default is `false` */
  pauseWhenEmpty?: boolean;
}

export default function SpeciesSearch(props: Props) {
  /** For pagination */
  const [from, setFrom] = useState(0);

  const { navigate } = useNavigation<StackNavigationProp<any>>();

  const pageSize = props.resultsPerPage ?? 50;

  const [{ data, fetching, error, stale }, refetch] = useSearchSpeciesQuery({
    variables: {
      size: pageSize,
      from,
      filter: props.query,
    },
    pause: props.pauseWhenEmpty && !props.query?.trim(),
    requestPolicy: 'cache-and-network',
  });

  const hasMore = (data?.searchSpecies?.total ?? 0) > from + pageSize;

  useEffect(() => {
    // If we change our search query, `from` is no longer valid in this context
    // and so we should reset it
    setFrom(0);
  }, [props.query]);

  useEffect(() => {
    if (fetching || stale) {
      props.onLoadStart?.();
    } else {
      props.onLoadEnd?.();
    }
  }, [fetching, props, stale]);

  const onPressSuggestNewSpecies = useCallback(
    function () {
      props.onPressSuggestSpecies?.();
      navigate('TakeSurvey', {
        placement: SystemSurveyPlacement.NewSpeciesSuggestion,
      });
    },
    [navigate, props],
  );

  function onScrollViewScroll({
    nativeEvent,
  }: NativeSyntheticEvent<NativeScrollEvent>) {
    if (isCloseToBottom(nativeEvent)) {
      onEndReached();
    }
  }

  const onEndReached = useCallback(() => {
    if (hasMore) {
      setFrom(from + pageSize);
    }
  }, [from, hasMore, pageSize]);

  const ListEmptyComponent = useMemo(() => {
    if (fetching || stale) {
      return (
        <ActivityIndicator
          size="large"
          style={{
            padding: 24,
          }}
          color={KEY_GRAY}
        />
      );
    }

    if (props.query && !data?.searchSpecies?.total) {
      return <Text style={commonStyles.emptyText}>No results</Text>;
    }

    return (
      <Text style={commonStyles.emptyText}>Search for species by name</Text>
    );
  }, [fetching, stale, props.query, data?.searchSpecies?.total]);

  const ListHeaderComponent = useMemo(() => {
    if (fetching || !props.query?.trim()) return null;

    if (props.hideSuggestButton) return null;

    return (
      <View
        style={{
          padding: 24,
          alignSelf: 'center',
          maxWidth: 320,
          paddingBottom: 0,
        }}
      >
        <Text
          style={{
            fontFamily: 'Lato-Bold',
            fontSize: 16,
            color: 'gray',
            textAlign: 'center',
          }}
        >
          Can't find what you're looking for and would like us to add it?
        </Text>
        <Button
          label="Suggest New Species"
          containerStyle={{
            marginVertical: 6,
            alignSelf: 'center',
          }}
          onPress={onPressSuggestNewSpecies}
        />
      </View>
    );
  }, [
    fetching,
    props.query,
    props.hideSuggestButton,
    onPressSuggestNewSpecies,
  ]);

  const ListFooterComponent = useMemo(() => {
    return error ? (
      <GenericError
        onRetry={refetch}
        message="There was a problem searching species"
      />
    ) : (
      <GenericListFooter
        hasMore={!!data?.searchSpecies?.total && hasMore}
        loading={fetching || stale}
        isFirstPage={!from}
        onFetchMore={onEndReached}
      />
    );
  }, [
    error,
    refetch,
    data?.searchSpecies?.total,
    hasMore,
    fetching,
    stale,
    from,
    onEndReached,
  ]);

  return props.useScrollView ? (
    // Use KeyboardAwareScrollView here
    <KeyboardAwareScrollView
      enableResetScrollToCoords={false}
      onScrollBeginDrag={() => {
        // Dismiss keyboard whenever user begins scrolling
        Keyboard.dismiss();
      }}
      onScroll={onScrollViewScroll}
      style={[{ flex: 1 }, props.style]}
    >
      {ListHeaderComponent}
      {!data?.searchSpecies.total
        ? ListEmptyComponent
        : data.searchSpecies.species.map((species) => {
            const onSelect = () => {
              props.onSelectSpecies(
                species,
                species.vernacularName || species.canonicalName,
              );
            };

            return <Item key={species.id} data={species} onSelect={onSelect} />;
          })}
      {data?.searchSpecies.species.length ? ListFooterComponent : null}
    </KeyboardAwareScrollView>
  ) : (
    <KeyboardAwareFlatList
      enableResetScrollToCoords={false}
      onEndReached={onEndReached}
      onEndReachedThreshold={0.2}
      onScrollBeginDrag={() => {
        // Dismiss keyboard whenever user begins scrolling
        Keyboard.dismiss();
      }}
      ListEmptyComponent={ListEmptyComponent}
      ListHeaderComponent={ListHeaderComponent}
      ListFooterComponent={
        data?.searchSpecies.species.length ? ListFooterComponent : null
      }
      renderItem={({
        item,
      }: {
        item: SearchSpeciesQuery['searchSpecies']['species'][0];
      }) => {
        const onSelect = () => {
          props.onSelectSpecies(
            item,
            item.vernacularName || item.canonicalName,
          );
        };

        return <Item data={item} onSelect={onSelect} />;
      }}
      style={[{ flex: 1 }, props.style]}
      data={data?.searchSpecies?.species}
    />
  );
}

interface ISpeciesSearchItemProps<
  DataType = SearchSpeciesQuery['searchSpecies']['species'][0],
> {
  data: DataType;
  onSelect: () => void;
}

type ItemNameProps = {
  textStyle?: TextStyle;
  boldTextStyle?: TextStyle;
};

const Item = ({ onSelect, data }: ISpeciesSearchItemProps) => {
  const VernacularName = useMemo(() => {
    let children: (JSX.Element | string)[] = [];

    let vernacularNameMatches =
      data.highlight?.vernacularName?.join('|') || data.vernacularName;

    vernacularNameMatches?.split(/<em>/).forEach((part, index) => {
      const match = part.match(/(.*)<\/em>/);

      const remainder = part.match(/<\/em>(.*)/);

      if (match) {
        children.push(
          <Text
            key={index}
            style={{
              fontFamily: 'LeagueSpartan-Bold',
            }}
          >
            {match[1]}
          </Text>,
        );
        if (remainder) {
          children.push(remainder[1]);
        }
      } else {
        children.push(part);
      }
    });

    return ({ textStyle }: ItemNameProps) => (
      <Text style={[styles.itemNameLarge, textStyle]}>{children}</Text>
    );
  }, [data.highlight?.vernacularName, data.vernacularName]);

  const CanonicalName = useMemo(() => {
    return ({ textStyle, boldTextStyle }: ItemNameProps) => {
      let children: (JSX.Element | string)[] = [];

      let canonicalNameMatches =
        data.highlight?.canonicalName?.join('|') || data.canonicalName;

      canonicalNameMatches?.split(/<em>/).forEach((part, index) => {
        const match = part.match(/(.*)<\/em>/);
        const remainder = part.match(/<\/em>(.*)/);

        if (match) {
          children.push(
            <Text
              key={index}
              style={[
                {
                  fontFamily: 'Lato-Bold',
                },
                boldTextStyle,
              ]}
            >
              {match[1]}
            </Text>,
          );

          if (remainder) {
            children.push(remainder[1]);
          }
        } else {
          children.push(part);
        }
      });

      return <Text style={[styles.itemNameSmall, textStyle]}>{children}</Text>;
    };
  }, [data.canonicalName, data.highlight?.canonicalName]);

  return (
    <TouchableOpacity
      onPress={() => {
        onSelect();
      }}
      style={{
        padding: 25,
        borderBottomColor: 'rgba(0,0,0,0.09)',
        borderBottomWidth: 1,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View style={{ flex: 1 }}>
          <VernacularName />
          <CanonicalName
            textStyle={
              data.vernacularName ? styles.itemNameSmall : styles.itemNameLarge
            }
            boldTextStyle={
              data.vernacularName
                ? { fontFamily: 'Lato-Bold' }
                : { fontFamily: 'LeagueSpartan-Bold' }
            }
          />
        </View>
        <IUCNStatusImage
          redlist_category={data.threatStatus}
          width={90}
          height={90}
        />
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  itemNameLarge: {
    fontSize: 20,
    fontFamily: 'LeagueSpartan',
    textTransform: 'capitalize',
  },
  itemNameSmall: {
    fontSize: 16,
    fontFamily: 'Lato',
    textTransform: 'capitalize',
  },
});
