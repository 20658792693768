import React, { useState } from 'react';
import { Animated, Pressable, StyleSheet, Text } from 'react-native';
import ScrollView from '/components/common/ScrollView/ScrollView';
import withAuthRequired from '/components/withAuthRequired';
import HorizontalContainer from '/components/common/Generic/HorizontalContainer';
import { AntDesign, Feather } from '@expo/vector-icons';
import AccountSettingsDrawer from '/components/AccountSettingsDrawer/AccountSettingsDrawer';
import {
  CreateResponsiveStyle,
  DEVICE_SIZES,
  maxSize,
} from 'rn-responsive-styles';
import { useAuthContext } from '/context';
import { TesterStatus } from '/generated/graphql';
import { KEY_GRAY } from '/constants';
import { useLinkTo } from '@react-navigation/native';

const DRAWER_WIDTH = 375; // Standard drawer width
const ANIMATION_DURATION = 250; // Animation duration in ms

function TesterDashboard() {
  const { styles } = useStyles();
  const linkTo = useLinkTo();
  const { userData } = useAuthContext();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const slideAnim = React.useRef(new Animated.Value(DRAWER_WIDTH)).current;

  const isApprovedTester =
    userData?.tester_status === TesterStatus.Approved || !!userData?.admin;

  function onReturnToPlatform() {
    linkTo('/');
  }

  const openDrawer = () => {
    setIsDrawerOpen(true);
    Animated.timing(slideAnim, {
      toValue: 0,
      duration: ANIMATION_DURATION,
      useNativeDriver: true,
    }).start();
  };

  const closeDrawer = () => {
    Animated.timing(slideAnim, {
      toValue: DRAWER_WIDTH,
      duration: ANIMATION_DURATION,
      useNativeDriver: true,
    }).start(() => {
      setIsDrawerOpen(false);
    });
  };

  return (
    <>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={styles('contentContainer')}
      >
        {isApprovedTester ? (
          <Pressable
            onPress={onReturnToPlatform}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: 10,
            }}
          >
            <AntDesign name="arrowleft" size={24} color={KEY_GRAY} />
            <Text style={styles('backToKeyPlatformText')}>
              Back to the Key platform
            </Text>
          </Pressable>
        ) : null}
        <HorizontalContainer
          style={{
            justifyContent: 'space-between',
          }}
        >
          <Text style={styles('title')}>Your Key Migration Dashboard</Text>

          <Pressable onPress={openDrawer}>
            <Feather size={40} name="menu" />
          </Pressable>
        </HorizontalContainer>
      </ScrollView>

      {/* Drawer Overlay */}
      {isDrawerOpen && (
        <Pressable
          style={[
            StyleSheet.absoluteFill,
            { backgroundColor: 'rgba(0,0,0,0.5)' },
          ]}
          onPress={closeDrawer}
        />
      )}

      {/* Animated Drawer */}
      <Animated.View
        style={[
          styles('drawer'),
          {
            transform: [{ translateX: slideAnim }],
          },
        ]}
      >
        {isDrawerOpen && <AccountSettingsDrawer onCloseDrawer={closeDrawer} />}
      </Animated.View>
    </>
  );
}

export default withAuthRequired(TesterDashboard);

const useStyles = CreateResponsiveStyle(
  {
    contentContainer: {
      padding: 50,
      paddingTop: 80,
    },
    title: {
      fontFamily: 'FranklinGothic-Bold',
      fontSize: 48,
    },
    drawer: {
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      width: DRAWER_WIDTH,
      backgroundColor: 'white',
      shadowColor: '#000',
      shadowOffset: {
        width: -2,
        height: 0,
      },
      shadowOpacity: 0.25,
      shadowRadius: 3.84,
      elevation: 5,
    },
    backToKeyPlatformText: {
      marginTop: 2,
      fontFamily: 'FranklinGothic-Bold',
      fontSize: 20,
      color: KEY_GRAY,
      marginLeft: 8,
    },
  },
  {
    [maxSize(DEVICE_SIZES.SMALL_DEVICE)]: {
      contentContainer: {
        padding: 20,
        paddingTop: 40,
      },
      title: {
        fontSize: 40,
      },
    },
  },
);
