import { Feather } from '@expo/vector-icons';
import { NavigationProp, RouteProp } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { ImageBackground, Text, View, useWindowDimensions } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { KeyboardAwareScrollView } from '@mtourj/react-native-keyboard-aware-scroll-view';
import { SafeAreaView } from 'react-native-safe-area-context';
import ConfirmResetPasswordForm from '../../components/Auth/ConfirmResetForm/ConfirmResetForm';
import ResetPasswordForm from '../../components/Auth/ResetPasswordForm/ResetPasswordForm';
import styles from './ResetPasswordScreen.style';
import { useAuthContext } from '../../context/AuthProvider';
import Alert from '/Alert';
import { KEY_GRAY } from '/constants';
import { useLoadingContext } from '/context';
import { isValidEmail } from '/util';

type ResetPasswordScreenProps = {
  navigation: NavigationProp<any>;
  route: RouteProp<any>;
};

const RESET_STATE = {
  SEND_CODE: 'Send Code',
  CHANGE_PASSWORD: 'Change Password',
};

export default function ResetPasswordScreen(props: ResetPasswordScreenProps) {
  const { resetPassword, confirmResetPassword } = useAuthContext();
  const [resetState, setResetState] = useState<string>(RESET_STATE.SEND_CODE);
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');

  const { height: windowHeight } = useWindowDimensions();

  const { setShowLoading }: any = useLoadingContext();

  useEffect(() => {
    if (props.route?.params?.email) setEmail(props.route.params.email);
  }, [props.route.params?.email]);

  function onGoBack() {
    if (!props.navigation.canGoBack()) {
      props.navigation.navigate('LoginScreen');
    } else props.navigation.goBack();
  }

  const onResetPassword = async () => {
    if (!isValidEmail(email)) {
      Alert.alert(
        'Invalid email',
        'Please make sure you have typed your email address correctly',
      );
      return;
    }

    if (newPassword !== confirmNewPassword) {
      Alert.alert(
        'Passwords do not match',
        'Please make sure you have entered your password correctly both times',
      );
      return;
    }

    try {
      setShowLoading(true);

      if (resetState === RESET_STATE.SEND_CODE) {
        await resetPassword(email);

        Alert.alert(
          'Reset Code Sent',
          'If an account with the email you provided exists, you should receive an email with a verification code you can use to reset your password.',
        );

        setResetState(RESET_STATE.CHANGE_PASSWORD);
      } else {
        await confirmResetPassword(email, verificationCode, newPassword.trim());

        onGoBack();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  };

  return (
    <View style={styles.container}>
      <SafeAreaView edges={['top']} style={styles.header}>
        <View style={styles.headerLeft}>
          <TouchableOpacity onPress={onGoBack} style={styles.backButton}>
            <Feather name="arrow-left" size={28} color="white" />
          </TouchableOpacity>
        </View>
        <Text style={styles.title}>Forgot Password</Text>
        <View style={styles.headerRight} />
      </SafeAreaView>
      <ImageBackground
        source={require('../../assets/images/greatgreen.png')}
        style={styles.background}
        resizeMode="cover"
      >
        <KeyboardAwareScrollView
          enableOnAndroid={true}
          style={{
            flex: 1,
            width: '100%',
            maxHeight: windowHeight,
          }}
          contentContainerStyle={styles.content}
        >
          {resetState === RESET_STATE.SEND_CODE ? (
            <ResetPasswordForm email={email} setEmail={setEmail} />
          ) : (
            <ConfirmResetPasswordForm
              email={email}
              verificationCode={verificationCode}
              setVerificationCode={setVerificationCode}
              newPassword={newPassword}
              setNewPassword={setNewPassword}
              confirmPassword={confirmNewPassword}
              setConfirmPassword={setConfirmNewPassword}
            />
          )}
          <View
            style={{
              width: '100%',
            }}
          >
            <TouchableOpacity
              style={styles.buttonContainer}
              onPress={onResetPassword}
            >
              <Text style={[styles.button, !email ? { color: 'gray' } : null]}>
                {resetState.toUpperCase()}
              </Text>
            </TouchableOpacity>
            {resetState === RESET_STATE.CHANGE_PASSWORD ? (
              <TouchableOpacity
                style={[styles.buttonContainer, { backgroundColor: KEY_GRAY }]}
                onPress={() => {
                  setResetState(RESET_STATE.SEND_CODE);
                }}
              >
                <Text style={[styles.button, { color: 'white' }]}>GO BACK</Text>
              </TouchableOpacity>
            ) : null}
          </View>
        </KeyboardAwareScrollView>
      </ImageBackground>
    </View>
  );
}
