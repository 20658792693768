import React, { Component } from 'react';
import {
  View,
  Text,
  Animated,
  Easing,
  LayoutChangeEvent,
  Pressable,
  StyleProp,
  ViewStyle,
} from 'react-native';

import ChevronLeftBlack from '../assets/jsicons/miscIcons/ChevronLeftBlack';
import styles from '../constants/Collapsible';
import { TextStyle } from 'react-native';

type ICollapsibleProps = React.PropsWithChildren<{
  title: string;
  titleStyle?: StyleProp<TextStyle>;
  right?: any;
  enabled?: boolean;
  collapsed?: boolean;
  style?: StyleProp<ViewStyle>;
  /**
   * Decide whether to render or clip child views
   */
  clipCollapsed?: boolean;
  onToggle?: (collapsed: boolean) => void;
}>;

interface ICollapsibleState {
  collapsed: boolean;
  animation: Animated.Value;
  headerHeight: number;
  bodyHeight: number;
}

const ARROW_COLLAPSED = '270deg';
const ARROW_OPEN = '90deg';

class Collapsible extends Component<ICollapsibleProps, ICollapsibleState> {
  content: React.RefObject<View>;

  constructor(props: ICollapsibleProps) {
    super(props);

    this.content = React.createRef();

    const typeOfRight = typeof props.right;

    if (typeOfRight !== 'undefined' && typeOfRight !== 'object') {
      console.error(
        'Prop `right` in Collapsible must be a valid React component!',
      );
    }

    // Initialize the collapsed state and animation value
    const collapsed = props.collapsed || false;
    const initialAnimationValue = collapsed ? 0 : 1;

    this.state = {
      collapsed,
      animation: new Animated.Value(initialAnimationValue),
      headerHeight: 0,
      bodyHeight: 0,
    };
  }

  toggle = (setCollapsed?: boolean) => {
    let collapsed = !this.state.collapsed;

    if (typeof setCollapsed === 'boolean') {
      collapsed = setCollapsed;
    }

    // this.state.animation.setValue(initialValue);
    Animated.timing(this.state.animation, {
      useNativeDriver: false,
      toValue: collapsed ? 0 : 1,
      easing: Easing.out(Easing.poly(4)),
    }).start();

    this.setState(
      {
        collapsed,
      },
      this.props.onToggle?.bind(this, collapsed),
    );
  };

  setHeaderHeight = (event: LayoutChangeEvent) => {
    this.setState({
      headerHeight: event.nativeEvent.layout.height,
    });
  };

  setBodyHeight = (event: LayoutChangeEvent) => {
    this.setState({
      bodyHeight: event.nativeEvent.layout.height,
    });
  };

  render() {
    // @ts-ignore
    const arrowRot = this.state.animation.interpolate({
      inputRange: [0, 1],
      outputRange: [ARROW_COLLAPSED, ARROW_OPEN],
    });

    const height = this.state.animation.interpolate({
      inputRange: [0, 1],
      outputRange: [
        this.state.headerHeight,
        this.state.bodyHeight + this.state.headerHeight,
      ],
    });

    return (
      <Animated.View style={[styles.container, this.props.style, { height }]}>
        <Pressable
          onPress={
            this.props.enabled === false ? undefined : () => this.toggle()
          }
        >
          <View
            style={styles.title_bar}
            onLayout={this.setHeaderHeight.bind(this)}
          >
            <Text style={[styles.title, this.props.titleStyle]}>
              {this.props.title}
            </Text>
            <View>{this.props.right}</View>
            <Animated.View
              style={[
                styles.arrowContainer,
                { transform: [{ rotate: arrowRot }] },
              ]}
            >
              <ChevronLeftBlack style={styles.arrow} />
            </Animated.View>
          </View>
        </Pressable>
        <View
          ref={this.content}
          style={[styles.content]}
          onLayout={this.setBodyHeight.bind(this)}
        >
          {this.props.clipCollapsed === true && this.state.collapsed === true
            ? null
            : this.props.children}
        </View>
      </Animated.View>
    );
  }
}

export default Collapsible;
